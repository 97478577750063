let fetchAsync = async function(query) {
    let response = await fetch(query);
  
    let data = await response.json();
  
    return data;
}

let authenticated = function(data) {
    //console.log(data);
    let retval = false;
    if(data.results.ERROR === undefined || data.results.ERROR === null) {
        retval = true;
    }
    else {
        if(data.results.ERROR != "user not authenticate") {
            retval = true;
        }
    }

    return retval;
}

export {authenticated,fetchAsync}