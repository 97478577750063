import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./blockchain/blockchainReducer";
import dataReducer from "./data/dataReducer";
import sessionReducer from "./session/sessionSlice";
import addressReducer from "./session/addressSlice";

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  data: dataReducer,
  session: sessionReducer,
  address: addressReducer
});

const middleware = [thunk];
const composeEnhancers = compose(applyMiddleware(...middleware));

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers);
};

const store = configureStore();

export default store;