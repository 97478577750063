import React, { useRef } from "react";
import ReactDOM from "react-dom";
//import { MoralisProvider, useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { useLocation } from "react-router-dom";
import { BrowserRouter as Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
//import Moralis from "moralis";
import {isMobile, deviceDetect} from "react-device-detect";
import detectEthereumProvider from "@metamask/detect-provider";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const qs = require("qs")
const env = process.env;


const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;

const Home = (props) => {
    // Moralis functions and properties.
    //const { authenticate, isAuthenticated, user, logout, enableWeb3 } = useMoralis();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const currentUser = useSelector((state) => state.session.currentUser)
    // Initialization of string state: This is the message that's displayed above the mint button.
    const [feedback, setFeedback] = React.useState(`Click mint to mint your NFT.`);

    // Initialization of string state: This is the state that displays the username in the welcome message.
    const [username, setUsername] = React.useState();

    // Initialization of string state: This is a test state. Remove this for production versions.
    //const [testfeedback, settestfeedback] = React.useState(`Test`)

    const dispatch = useDispatch();
    
    async function logout() {
        dispatch(setSession(null));
        
        dispatch(disconnect());
        const logoutQuery = `${env.REACT_APP_BASEURLSERVICE}/auth.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}`

        const postLogoutObj = {
        type:5,
        
        sid: currentUser
        };

        await axios.post(logoutQuery, qs.stringify(postLogoutObj))
    }

    // Runs on pageload if user is authenticated by Moralis. Currently it just fills in the username state.
    
    React.useEffect(() => {
        if(currentUser) {
            async function gaming() {
            //console.log('location', location.pathname); // { key: "3uu089" }
            // Fire whatever function you need.

            // Initializes all the variables that go into the query
            const id = currentUser

            const typeoneadd = `${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${id}&c=${env.REACT_APP_COLLECTIONADD}`

            console.log(typeoneadd)

            // Sends the fetch to SQL server, then sets the username it receives from it. 
            const c = await fetchAsync(typeoneadd).then(async (unameRes) => {
                if(unameRes.results.ERROR) {
                    throw ""
                } else {
                    let auth = authenticated(unameRes);
                    if(!auth) {
                        logout();
    
                        toast.error("Unauthenticated session. Please log in again.");
                    }
                    else {
                        const uname = unameRes.data[0].userName;
                    
                        setUsername(uname);
                    }
                }
            }).catch(err => {
                logout();

                toast.error("Encountered an error. Please log in again.");
            })
            }
            gaming();
        }
    }, [currentUser]);

    const location = useLocation();

    // These two functions raise or lower the amount that you're trying to mint
    // TO DO: Add a limit. Can't go lower than 1, can't go higher than 9/10 at once, irrespective of the contract limit.

    // This is a test function. Remove it later. It grabs the device data of the user and sets the testfeedback state to it.
    /*async function logBrowser() {
        const a = deviceDetect();

        settestfeedback(JSON.stringify(a));
    }*/

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
          dispatch(fetchData(blockchain.account));
        }
    };
    
    React.useEffect(() => {
        getData();
    }, [blockchain.account]);

    /*<div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
            <source src="/images/bg.mp4" type="video/mp4" />
        </video>
    </div>*/

    return (
        <div id="pagetop" className="background-image-div d-inline-flex flex-column">
            <div className="bg-div">
                <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                    <source src="/images/bg_final.mp4" type="video/mp4" />
                </video>
            </div>
            
            <div className="div-background-content">
                <div className="div-content mx-auto bg-home p-lg-5 p-3">

                    <div className="container">  
                        <div className="row">
                            <div className="col-lg-7 col-12">
                                <img className="img-fluid" src="images/Cards.png" />
                                    
                            </div>
                            <div className="my-lg-auto col-lg-5 col-12">
                                <h1>Ethball Fantasy Baseball Cards Collection</h1>
                                <p>Ethball Fantasy Baseball Cards is the first digital card collection where the cards can also participate in a fantasy baseball league. Build a team and compete in Ethball - there’s no other game like it in the Metaverse!</p>
                            </div>
                        </div>
                    </div>
                    

                    <hr className="hr hr-home" />

                    <h2>Details</h2>

                    <p>EFBC is a collection of 10,000 Digital Baseball Cards that exist on the Ethereum blockchain as ERC-721 tokens - each one a unique digital baseball card of a fictional Ethball player. The Ethball players are generated from a bunch of possible traits, some which make the Ethball player look bomb, and others that help in the Fantasy Baseball Game.</p>

                    <p>While the baseball cards are stored on the Ethereum blockchain as ERC-721 tokens, the card images are stored on IPFS, with backups on Arweave, which means they are here to stay for the long haul. To purchase an Ethball card you only need enough Ethereum to cover gas fees, the cards are free to mint!.</p>

                    <p>To access Fantasy Baseball areas, EFBC owners will need to login to the website with their Metamask wallet.</p>

                    <hr className="hr hr-home" />

                    <div className="container align-self-start px-0 mx-0">
                        
                        <div className="row">
                            <div className="col-lg-7 col-12">
                                <h2>The Collection</h2>
                                <p>Baseball cards used to come in packs with a cheap stick of bubble gum only good for an emergency visit to the dentist and cards only worth being inserted into the spokes of your bike.</p> 
                                <p>Ethball Baseball Cards are much more: verifiably rare art representing a first of its kind offering of both art and a passport into fantasy sports, Metaverse style. So grab a pack or two and skip the dental visit!</p>
                            </div>
                            <div className="my-lg-auto col-lg-4 col-12 offset-lg-1">
                                <img className="img-fluid" src="images/Ethball_Samples.gif" />
                            </div>
                        </div>
                    </div>

                    <hr className="hr hr-home" />

                    

                    <div className="container align-self-start px-0 mx-0">
                        
                        <div className="row">
                            <div className="col-12">
                                <h2>Collection Perks</h2>

                                <p>Purchase of Ethball Fantasy Baseball Cards allows for participation in the first-ever digital card collection where the traits map to real-world players represented by our Composite Players.</p> 

                                <p>Based on Roadmap Achievements we will activate additional Fantasy Leagues, Airdrop some dope Baseball Cards, and other cool stuff.</p>
                            </div>
                            <div className="my-lg-auto col-12">
                                <h3 className="mb-4">Hitters</h3>
                                <div className="row">
                                    
                                    <div className="col-lg-4 col-12">
                                        <div className="row mb-4">
                                             <div className="col-lg-12 col-4">
                                                <img className="img-fluid" src="images/24-shoes-hitter.png" />
                                            </div>
                                            <div className="col-lg-12 col-4 m-auto">
                                                    <p>Shoes - Stolen Bases</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-12">
                                        <div className="row mb-4">
                                            <div className="col-lg-12 col-4">
                                                <img className="img-fluid" src="images/55-muscles-hitter.png" />
                                            </div>
                                            <div className="col-lg-12 col-4 m-auto">
                                                <p>Muscle Size - Home Runs</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-12">
                                        <div className="row mb-4">
                                            <div className="col-lg-12 col-4">
                                                <img className="img-fluid" src="images/99-bat-hitter.png" />
                                            </div>
                                            <div className="col-lg-12 col-4 m-auto">
                                                <p>Bat Color - Batting Average</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="mb-4">Pitchers</h3>
                                <div className="row">
                                    
                                    <div className="col-lg-4 col-12">
                                        <div className="row mb-4">
                                             <div className="col-lg-12 col-4">
                                                <img className="img-fluid" src="images/24-shoes-pitcher.png" />
                                            </div>
                                            <div className="col-lg-12 col-4 m-auto">
                                                    <p>Shoes - Innings Pitched</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-12">
                                        <div className="row mb-4">
                                            <div className="col-lg-12 col-4">
                                                <img className="img-fluid" src="images/24-muscles-pitcher.png" />
                                            </div>
                                            <div className="col-lg-12 col-4 m-auto">
                                                <p>Muscle Size - Strikeouts</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-12">
                                        <div className="row mb-4">
                                            <div className="col-lg-12 col-4">
                                                <img className="img-fluid" src="images/50-hat-pitcher.png" />
                                            </div>
                                            <div className="col-lg-12 col-4 m-auto">
                                                <p>Hat - Saves (Relievers only)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <hr className="hr hr-home" />

                    <div className="container align-self-start px-0 mx-0">
                        
                        <div className="row">
                            <div className=" col-12">
                                <h2>Composite Players</h2>

                                <p>In the Metaverse anything goes, and the same goes for your baseball cards. Based on certain traits, each Ethball Baseball Card represents a unique amalgamation of three real-life players. Translated, that means “no 2 Composite Players are alike”. (See the About page for more details).</p>
                            </div>
                            <div className="my-lg-auto  col-12">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4 col-12">
                                            <div className="row mb-4">
                                                <div className="col-lg-12 col-8">
                                                    <img className="img-fluid" src="images/Sample1.png" />
                                                </div>
                                                <div className="col-lg-12 col-4 m-auto">
                                                    <p>Level 1 Muscles</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-12">
                                            <div className="row mb-4">
                                                <div className="col-lg-12 col-8">
                                                    <img className="img-fluid" src="images/Sample2.png" />
                                                </div>
                                                <div className="col-lg-12 col-4 m-auto">
                                                    <p>Level 3 Muscles</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-12">
                                            <div className="row mb-4">
                                                <div className="col-lg-12 col-8">
                                                    <img className="img-fluid" src="images/Sample3.png" />
                                                </div>
                                                <div className="col-lg-12 col-4 m-auto">
                                                    <p>Level 5 Muscles</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    

                    <hr className="hr hr-home" />

                    <div className="container align-self-start px-0 mx-0">
                        
                        <div className="row">
                            <div className="col-lg-7 col-12">
                                <h2>Prizes</h2>

                                <p><i>TBD</i></p>
                            </div>
                            <div className="my-lg-auto col-lg-4 col-12 offset-lg-1">
                                <img className="img-fluid" src="images/Ethball_Trophy.gif" />
                            </div>
                        </div>
                    </div>

                    

                    <hr className="hr hr-home" />

                    <h2>Roadmap</h2>

                    <p>TBD after Alpha/Beta period is completed</p>


                    <hr className="hr hr-home" />

                    <h2>Team</h2>
                    <p>
                        <b style={{color:"Yellow"}}>Dexmar </b> <i>More soon!</i>
                    </p>
                    <p>
                        <b style={{color:"Yellow"}}>Chumpmaterial </b> <i>More soon!</i>
                    </p>
                    <p>
                        <b style={{color:"Yellow"}}>Mr. Artman </b> <i>More soon!</i>
                    </p>
                </div>
            </div>       
            
        </div>
    );

};

export default Home;