import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";

const Onboard = () => {
  //const { authenticate, isAuthenticated, user, logout } = useMoralis();
  /*
  if (!isAuthenticated) {
    return (
      <div id="pagetop" className="background-image-div">
        <div className="bg-div">
            <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                <source src="/images/bg.mp4" type="video/mp4" />
            </video>
        </div>
        
        <div className="div-background-content">
            <div className="align-self-center mx-auto div-content">
                <p></p>
                
            </div>
        </div>
      </div>
    );
  }*/

  return (
    <div id="pagetop" className="background-image-div">
        <div className="bg-div">
          <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
            <source src="/images/bg_final.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="div-background-content">

            <div className="mx-auto div-content bg-home px-5 py-3">
                <h1>Getting Started</h1>
                <p>
                Details about how to get started will be revealed shortly!
                </p>
            </div>
        </div>
    </div>
  );
};

export default Onboard;