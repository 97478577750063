import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";

const Roadmap = () => {
  //const { authenticate, isAuthenticated, user, logout } = useMoralis();
  /*
  if (!isAuthenticated) {
    return (
      <div id="pagetop" className="background-image-div">
        <div className="bg-div">
            <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                <source src="/images/bg.mp4" type="video/mp4" />
            </video>
        </div>
        
        <div className="div-background-content">
            <div className="align-self-center mx-auto div-content">
                <p></p>
                
            </div>
        </div>
      </div>
    );
  }*/

  return (
    <div id="pagetop" className="background-image-div">
        <div className="bg-div">
          <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
            <source src="/images/bg_final.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="div-background-content">

          <div className="mx-auto div-content bg-home px-5 py-3">
            <h1>About</h1>
            <p>
              Calling all Ape sport fans! Tired of lying around in your virtual digs staring at the Matrix endlessly for hours? We’ve got just the fantasy baseball fix you’ve been looking for, Ethball Fantasy Baseball Cards!
            </p>
            
            <hr className="hr hr-home" />

            <h2>About Ethball Fantasy Baseball Cards</h2>
            <p>Ethball Fantasy Baseball is a collection of virtual baseball cards that represent fictional baseball players in the Metaverse. As a digital collection, the baseball cards represent pieces of art with value as defined by the collectors. As an Ethball fantasy baseball game, it is a game of skill and knowledge where participants can follow the season-long, accumulated statistical results of professional sports players and have the results of those efforts count towards their Ethball Fantasy team results. Ethball Fantasy Baseball does not require one to participate in an Ethball League to collect or enjoy the baseball cards in the collection as they are pieces of collectible art in their own right, not unlike real baseball cards themselves. However, should a collector opt to become a participant, an additional dimension opens itself up beyond what nearly every other digital collection has to offer.</p>
            <br />
            <p>Every collector and participant should thoroughly read and understand this document. Any questions regarding the posted rules can be answered by opening a help ticket on the Ethball Discord channel.</p>

            <hr className="hr hr-home" />

            <h2>Rotisserie-style Fantasy Baseball</h2>

            <p>Fantasy baseball has long been the vehicle by which even the most casual fans of the sport can become more than just a spectator. It puts you in the driver’s seat and lets you build a team using any strategy you can imagine. The granddaddy of all is the original <a href="https://en.wikipedia.org/wiki/Fantasy_baseball#Rotisserie_League_Baseball">Rotisserie-style</a> fantasy baseball league. Similar to <a href="https://en.wikipedia.org/wiki/Divide_and_choose">Divide and Choose</a>, it is simplistic in design, but devilishly clever and difficult to master.</p>
            <br/>
            <p>For those new to the hobby, fantasy sports allow for anyone to be in charge of one aspect of running a sports team - personnel decisions. At its heart, fantasy sports (and specifically in this case, fantasy baseball) appear simple: get the best players and outperform thy competitors. In practice, the number of strategic options available to a General Manager of a fantasy team are more numerous than the number of participants, but beware, even the best laid plans can be dashed by a subpar performance or an untimely injury.</p>

            <br/>
            <p>There are many types of fantasy baseball leagues popular today, such as Daily Fantasy, Head-to-Head, and Dynasty leagues, but ultimately, their origin can be traced to Rotisserie-style fantasy baseball, which was the first fantasy sports game of its kind that was widely adopted. The brainchild of writer Daniel Okrent and a group of friends who met at the La Rotisserie Française restaurant in New York City in 1980 and later popularized by the 1984 book by Okrent titled “Rotisserie League Baseball”, Rotisserie-style baseball is still one of the easiest type of fantasy baseball games to learn. But don’t be fooled, it is not easy. So how does this style of fantasy league work? In the original leagues run by Okrent & Co., a team would select a fixed number of pitchers and hitters and their real-life performance in professional baseball would be totaled and compared to other teams in the league to create standings. The original group of stats, 4 for hitters and 4 for pitchers (known as 4x4 scoring) were (for hitters): batting average, home runs, runs batted in (rbi), and stolen bases. For pitchers they were wins, saves, earned run average (era), and WHIP (walks + hits / innings pitched). Since the original leagues, there have been countless changes to the stats tracked and Ethball will also make some changes to the tracked stats.</p>
            <br/>
            <p>So, what is Ethball and how does it work? Ethball is a collection of digital baseball cards set in a far off future (or is it the past? does it even matter?) in the Metaverse. A participant in this collection can acquire as many cards as they like to create a team and compete against other teams in the league of their choice. The statistics tracked (for more on Tracked Stats, see that section below) are as follows:</p>

            <br/>
            <p><b>Hitters:</b><br/>
            <ul>
              <li>Batting Average</li>
              <li>Home Runs</li>
              <li>Runs Batted In (rbi)</li>
              <li>Runs Scored</li>
              <li>Stolen Bases</li>
            </ul>
            </p>
            
            <br/>
            <p><b>Pitchers:</b> <br/>
            <ul>
              <li>Wins</li>
              <li>Saves</li>
              <li>Earned Run Average (era)</li>
              <li>Innings Pitched</li>
              <li>Strikeouts</li>
            </ul>
            </p>
            

            <br/>

            <p>The statistics accrued by each of the Ethball players on each Ethball team is totaled daily and then each Ethball team is ranked against the other Ethball teams in the league to create the League Standings. Each league will show the top ten Ethball teams in the standings. The Ethball team with the most home runs will receive 10 points for the home run category; the Ethball team with the second most home runs will receive 9 points. This calculation is made for each of the tracked statistics listed above. The Ethball team with the most combined points from all of the Tracked Stat categories is in first place as of that day, the team with the second most points is in second place, and so on. The Ethball team with the most points on the final day of the professional baseball regular season schedule is crowned the winner and will receive the first place prize trophy (for more, see Prizes below).</p>

            <br/>
            <p>If you’ve made it through the summary, buckle up, we are gonna get into the inner workings of what makes Ethball tick.</p>
            
            <hr className="hr hr-home" />

            <h2>Composite Players</h2>

            <p>Each Ethball baseball card (hereafter referred to as Ethball players) in the Ethball digital collection is composed of 3 professional baseball players, which form a single composite. The 3 professional players contribute to the daily stats generated by the Ethball player on a rotating basis; one of the professional player stats each day are counted towards the totals of the Ethball player while the other 2 professional players’ stats are not. As these rotate on a daily basis, the total contribution of each of the professional players is equal (⅓ each). The order of the 3 professional players is unique within the collection, which ensures that no 2 Ethball players are alike.</p>

            <br/>

            <p>The 3 professional players that make up the Ethball hitter composites are determined by the following traits on the digital baseball card:<br/>
              <ul>
                <li>Muscle Size (5 levels) maps to home run ability<ul>
                  <li>Level 5: Largest</li>
                  <li>Level 4: Large</li>
                  <li>Level 3: Average</li>
                  <li>Level 2: Small</li>
                  <li>Level 1: Puny</li>
                </ul></li>
                <li>Bat Color (5 levels) maps to batting average <ul>
                <li>Level 5: Silver</li>
                  <li>Level 4: Black</li>
                  <li>Level 3: Wood Grain</li>
                  <li>Level 2: Beat-up Wood Grain</li>
                  <li>Level 1: Full of Holes</li>
                </ul></li>
                <li>Shoes (5 levels) map to base stealing ability<ul>
                  <li>Level 5: Winged</li>
                  <li>Level 4: Track Spikes</li>
                  <li>Level 3: Regular Cleats</li>
                  <li>Level 2: Holes in shoes</li>
                  <li>Level 1: Cinderblocks</li>
                </ul></li>
              </ul>
            </p>

            <br/>

            <p>Pitchers are created slightly differently. They are first subcategorized into relief pitchers and starting pitchers and then pitching composites are generated using the following traits:<br/>
              <ul>
                <li>Muscle Size (5 levels) maps to strikeout ability<ul>
                <li>Level 5: Largest</li>
                  <li>Level 4: Large</li>
                  <li>Level 3: Average</li>
                  <li>Level 2: Small</li>
                  <li>Level 1: Puny</li>
                </ul></li>
                <li>Shoes (5 levels) map to innings pitched<ul>
                <li>Level 5: Winged</li>
                  <li>Level 4: Track Spikes</li>
                  <li>Level 3: Regular Cleats</li>
                  <li>Level 2: Holes in shoes</li>
                  <li>Level 1: Cinderblocks</li>
                </ul></li>
                <li>Hats (5 levels) map to saves ability (relievers only)<ul>
                  <li>Level 5: Fireman Helmet</li>
                  <li>Level 4: Police Hat</li>
                  <li>Level 3: Ballcap</li>
                  <li>Level 2: Backwards Ballcap</li>
                  <li>Level 1: Propeller Cap</li>
                </ul></li>
              </ul>
            </p>

            <br/>

            <p>All professional players at the major league level from the previous season are rated on their ability in each of the above-mentioned trait categories. With a few exceptions, players who did not meet a minimum amount of playing time were dropped. The top 7.5% of players in each trait category rank as a level 5 (the highest level) while the bottom 7.5% of players are level 1. The bulk of the players (60%) in each trait category are level 3.</p>

            <br/>

            <p>3 professional players are assigned to each Ethball player–one professional player from each trait category mentioned above. So for example, if an Ethball hitter has level 5 muscles (home runs), level 3 bat (batting average), and level 1 shoes (stolen bases) it will be assigned one elite (level 5) professional home run hitter from the previous season, one average (level 3) skilled professional player in batting average from the previous season, and one professional hitter from the previous major league season who was very poor (level 1) at stealing bases.</p>

            <br/>

            <p><b>How are composite players used in Ethball?</b></p>

            <p>Each baseball card will have a real baseball player represented by a unique combination of Composite 1, Composite 2, and Composite 3 players. Hypothetically, let’s say that digital edition #832 baseball card from the Ethball collection has the following 3 composites:<br/>
              <ul>
                <li>Composite 1 player (muscles - home runs) is Vladimir Guerrero Jr</li>
                <li>Composite 2 player (bat - batting average) is Kris Bryant</li>
                <li>Composite 3 player (shoes - stolen bases) is Mike Moustakas</li>
              </ul>
            </p>

            <p>The statistics edition #832 would generate would be determined based on the day of the season:<br/>
              <ul>
                <li>On April 7th (opening day): all composite 1 players generated stats are credited to their player card. In the case of edition #832, Vladimir Guerrero gets 1 hit in 4 at bats with 2 rbi, then those stats are added to edition #832</li>
                <li>On April 8th (the second day of the season): composite 2 players generated stats are credited to their player card. In the case of edition #832, Kris Bryant gets 0 hits in 3 at bats, then those stats are added to edition #832</li>
                <li>On April 9th (third day of the season): composite 3 players generated stats are credited to their player card. In the case of edition #832, Mike Moustakas gets 1 hit in 3 at bats (a home run) with 1 run scored and 1 rbi.</li>
                <li>On April 10th (the fourth day of the season): composite 1 players generated stats are credited to their player card. And so the cycle repeats with Vladimir Guerrero and his performance getting credited to edition #832.</li>
              </ul>
            </p>

            <p>After 3 days of the season, edition #832 would have 10 at bats, 2 hits, 1 home run, 1 run scored, 3 rbi, and 0 stolen bases.</p>

            <hr className="hr hr-home" />

            <h2>Tracked Stats</h2>

            <p>All statistics used in Ethball are provided by data providers and are believed to be accurate as scored by Official Scorers of professional leagues. As mentioned earlier, the statistics tracked in Ethball (and formulas, as needed) are:</p>

            <br/>

            <p><b>For Hitters:</b><br/>
              <ul>
                <li><b>Batting Average</b> - at bats divided by hits rounded to the thousandths (important note: as a rate stat, a team will need a minimum number of at bats to qualify in this category in the standings, for more see Scoring section below)</li>

                <li><b>Home Runs</b> - a hit that allows a hitter to make a complete circuit of the bases without stopping</li>

                <li><b>Runs Batted In (rbi)</b> - credited to a hitter when one or more runs score as a result of the hitter’s plate appearance</li>

                <li><b>Runs Scored</b> - when a player touches home plate and scores for their team</li>

                <li><b>Stolen Bases</b> - credited to a player who advances a base, not as a result of an outcome by a hitter or a poor play by a fielder</li>
              </ul>
            </p>

            <br/>

            <p><b>Pitchers:</b><br/>
              <ul>
                <li><b>Wins</b> - credited to a pitcher of record when their team takes the lead for good</li>

                <li><b>Saves</b> - credited to a relief pitcher who finishes a game under certain circumstances; a pitcher cannot win and save the same game</li>

                <li><b>Earned Run Average (era)</b> - (earned runs x 9) divided by innings pitched (important note: as a rate stat, a team will need a minimum number of innings pitched to qualify in this category in the standings, for more see Scoring section below)</li>

                <li><b>Innings Pitched</b> - measures the number of innings a pitcher has completed; there are 3 outs in an inning so each out represents ⅓ of an inning</li>

                <li><b>Strikeouts</b> - occurs when a pitcher throws 3 pitches in a plate appearance that the batter swings and misses at or does not swing but the pitch is in the strike zone</li>
              </ul>
            </p>

            <hr className="hr hr-home" />

            <h2>Requirements to participate</h2>
            
            <p>
              <ul>
                <li>A <a href="https://metamask.io/">MetaMask</a> wallet or equivalent <a href="https://en.wikipedia.org/wiki/Web3">Web3</a> wallet</li>

                <li>Ethereum in your Web3 wallet</li>

                <li>At least a basic understanding of what digital tokens are and how to manage them</li>

                <li>Access to <a href="https://opensea.io/">OpenSea</a> marketplace</li>

                <li>Access to <a href="https://ethball.io/">EthBall</a> website</li>
              </ul>
            </p>

            <p>IT IS VERY IMPORTANT TO <a href="https://community.metamask.io/t/start-here-metamask-setup-with-security-best-practices/3278">SECURE</a> YOUR WALLET! SO PLEASE, PLEASE, PLEASE TAKE THE TIME TO DO SO!</p>

            <hr className="hr hr-home" />

            <h2>Player Acquisition</h2>

            <p>Ethball NFT player cards can be acquired the following ways:<br/>
              <ul>
                <li>Minted on the ethball.io minting DApp (Here!)</li>
                <li>Minted directly on the contract at etherscan.io</li>
                <li>Via Whitelist</li>
                <li>Purchase on OpenSea</li>
                <li>For a few lucky owners, Via AirDrop (if AirDrop Roadmap goals are met)</li>
              </ul>
            </p>

            <p>If participating in a fantasy league, newly acquired player(s) are inactive by default. Participants must go to ethball.io to activate the newly acquired player(s).</p>

            <hr className="hr hr-home" />

            <h2>Teams</h2>

            <p>For eligible participants (see eligibility section below and in Terms of Service), the acquisition of one or more Ethball players allows for the creation of a team. Once a participant has created a team, they can join a league (see next section, Leagues for more info). League competition is where a team is able to compete for one of the prize trophies. A team must have a minimum of one Ethball player to be created. A team may have as many Ethball players as the participant would like, but can only have a maximum of 9 active Ethball players at one time. The participant managing the team may utilize any combination of hitters and pitchers as they deem necessary to carry out their strategy, including having all pitchers or all hitters. To be counted for the next day of stats, an Ethball player must be activated before 12 am (midnight) Pacific Time. An Ethball player that is acquired but not activated will not have stats count towards a team. The stats accrued by each active Ethball player for each Tracked Stat (see Tracked Stats above) are added to a team total and the team totals are compared to all of the other teams in the league to determine the standings (for more info on how standings work, see the Scoring section below). The top teams in each league are awarded prize trophies (see Prizes below for more info).</p>

            <hr className="hr hr-home" />

            <h2>Leagues</h2>

            <p>A league is a collection of teams competing against each other for one of the prize trophies. The league will have a standings board and will award prize trophies (see Prizes below). The league will start play on MLB Opening Day.</p>

            <hr className="hr hr-home" />

            <h2>Scoring</h2>
            <p>Each Ethball players stats from the 5 Tracked Stat (see Tracked Stats above for more details) categories for both pitchers and hitters are totaled and then ranked from highest to lowest. The top ten in each category are awarded an inverse number of points based on their position in the rankings (rate stats also have a minimum to qualify for the standings - Batting Average requires 5 at bats per scheduled day of the Ethball League that the Ethball team is in and Earned Run Average requires 1 Inning Pitched per scheduled day of the Ethball League that the Ethball team is in). This means that First Place in a Tracked Stat category will earn an Ethball team 10 points for that category, Second Place in a Tracked Stat category will earn an Ethball team 9 points, down to Tenth Place in a Tracked Stat category will earn an Ethball team 1 point. The points awarded for all 10 Tracked Stat categories are totaled and the team with the most points will be in First Place in the standings for that Ethball league. Each Ethball league will have their own standings. Please keep in mind that Tracked Stats will only be accrued for your Ethball players and your Ethball team from professional league games that begin and are played after your Ethball League has started play.</p>

            <br/>

            <p><b>Tiebreakers:</b> In the event that 2 or more Ethball teams tie in a Tracked Stat category, each tied participant will receive an average of the number of points available to each position in the rankings out of the sum of the total points available for each Ethball team that is tied. Some examples:</p>

            <p><b>Scenario 1:</b> 2 Ethball teams tie for First Place in a Tracked Stat category, the rest of the positions in the rankings are not tied. <br/>
              <ul>
                <li>	First Place (tie) - Ethball Team 1 & Ethball Team 2 would receive an average of the First Place points (10) + Second Place points (9) = (10 + 9) divided by 2 teams = 9.5 points awarded in the Standings for each of the teams for this Tracked Stat category.</li>
                <li>	Third Place down to Tenth Place - each team would receive the points for their ranking (ie, Third Place team would receive 8 points, Fourth Place would receive 7 points, down to Tenth Place team would receive 1 point).</li>
              </ul>
            </p>
            <p><b>Scenario 2:</b> 3 Ethball teams tie for Second Place in a Tracked Stat category, the rest of the positions in the rankings are not tied.<br/>
              <ul>
                <li>First Place - Ethball Team 1 receives 10 points for this Tracked Stat category</li>
                <li>Second Place (tie) - Ethball Team 2, Ethball Team 3, Ethball Team 4 would receive an average of the Second (9), Third (8), and Fourth Place points (7) = (9 + 8 + 7) divided by 3 teams = 8 points awarded in the Standings for each of the teams for this Tracked Stat category.</li>
                <li>Fifth Place - Tenth Place would be awarded the amount of points for that ranking to each of the Ethball Teams in those positions in the rankings (ie, Fifth Place, 6 points, Sixth Place, 5 points, down to Tenth Place, 1 point).</li>
              </ul>
            </p>
            <p><b>Scenario 3:</b> 2 Ethball teams tie for Tenth Place in a Tracked Stat category, the rest of the positions in the rankings are not tied.<br/>
              <ul>
                <li>First Place - Ninth Place teams would receive the respective number of points for their ranking (First Place, 10 points, etc). The 2 Ethball teams tied for Tenth Place would divide the Tenth Place point (1) and each would be awarded 0.5 points in the Standings for this Tracked Stat category.</li>
              </ul>
            </p>

            <br/>

            <p>Please keep in mind that Tracked Stats will only be accrued for your Ethball players and your Ethball team from professional league games that begin and are played after the Ethball League has started play.</p>

            <hr className="hr hr-home" />

            <h2>Stat corrections</h2>
            <p>Should there be an official scoring change in the professional baseball league, the changes will be implemented into the Ethball Fantasy Baseball game as soon as the updates are available from our stat provider. In the event that there are stat corrections after the Ethball Fantasy Baseball game has concluded, those corrections will not be applied. Any situations not covered above will be decided by the Ethball Fantasy League staff.</p>

            <hr className="hr hr-home" />

            <h2>Prizes</h2>
            <p>During test Alpha/Beta phase, prizes TBD<br/>
              <ul>
                <li>First Place trophy TBD</li>

                <li>Second Place trophy TBD</li>

                <li>Third Place trophy TBD</li>

                <li>Fourth Place trophy TBD</li>
              </ul>
            </p>

            <p><b>Tiebreakers:</b> In the event that 2 or more Ethball teams have the same point total after the final regular season professional games have been played, the Ethball teams that are tied will combine the prize trophy award that they tied for and each of the next lower prize trophies per Ethball team that is tied. The value of the prize trophies for Ethball teams that tie would be the sum of the value of all of the prize trophy positions per tied Ethball team divided by the number of Ethball teams that are tied. Some examples:</p>

            <hr className="hr hr-home" />

            <h2>Eligibility</h2>

            <p><b>EFBC Purchase eligibility</b></p>
            
            <p><i>Eligibility rules do not apply in Alpha/Beta testing period</i></p>

            <p>Anyone who is eligible to purchase a blockchain based Ethball digital card in their jurisdiction may do so.</p>

            <br/>

            <p><b>Fantasy Baseball League eligibility</b></p>
            <p>No one may enter an Ethball Fantasy Baseball league where such fantasy sports games with prizes are prohibited. As such, to be eligible to participate a collector must meet ALL of the following:<br/>
              <ul>
                <li>Be physically located in one of the 50 United States (see Exceptions below) or District of Columbia, or</li>
                <li>Be physically located in Canada (see Exceptions below)</li>
                <li>You must be 21 years of age if you are physically located in Arizona or Massachusetts, 19 years of age if you are physically located in Alabama or Nebraska, and 18 years of age in all other eligible areas in the United States or Canada</li>
              </ul>
            </p>

            <br/>

            <p><b>Exceptions: (Those not eligible)</b><br/>
              <ul>
                <li>If you are physically located in Connecticut, Hawaii, Idaho, Iowa, Louisiana, Missouri, Montana, Nevada, Washington, or tribal lands in Arizona, then you are not eligible to participate in the Ethball Fantasy Baseball League, or</li>
                <li>If you are physically located in Quebec, then you are not eligible to participate in the Ethball Fantasy Baseball League</li>
                <li>Anyone on a United States government watch list is not eligible to participate in the Ethball Fantasy Baseball League</li>
                <li>Anyone involved in the creation of the Ethball Fantasy Baseball League is not eligible to participate in the Ethball Fantasy Baseball League</li>
              </ul>
            </p>

            <br/>

            <p><b>Eligibility Verification</b></p>

            <p>Upon completion of the Ethball Fantasy Baseball season, prize winners will be required to complete age and address verification to ensure eligibility based on their jurisdiction (see Prizes section above for more details). Verification is only required of prize winners. Participants found to be ineligible will not be awarded a prize. This will cause each team below the ineligible team to move up in the standings. So for example, if a first place team in a league is found to be ineligible, the second place team will now be awarded first place, third place will now be awarded second place, etc.</p>

            <br/>

            <h2>Changes to the About Page</h2>

            <p>To ensure the integrity of the Ethball Fantasy Baseball League(s), in fairness to all of the participants, for competitive balance reason, and any other reason we deem important, we reserve the right to make changes, as necessary, to the rules set out in this document. In the event we consider it necessary and proper to make any changes, this document will be updated with the changes and they will be communicated to the community via our Discord Server.</p>
          </div>
        </div>
    </div>
  );
};

export default Roadmap;