import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";
//import Moralis from "moralis";
import { useLocation, Navigate } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import Dropzone from "react-dropzone";
import $ from "jquery";
import axios from "axios";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
const qs = require("qs");

const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;

const Team = () => {

  // Moralis functions and properties.
  //const { authenticate, isAuthenticated, user, logout,  } = useMoralis();

  const [count, setCount] = React.useState(0);

  const [totalCount, setTotalCount] = React.useState(0);

  const [redirect,setRedirect] = React.useState(false);

  // Initialization of string state: Display for user's team name upon the page loading.
  const [teamName, setTeamName] = React.useState("");

  const [allowTeamSub, allowTSub] = React.useState(false);

  const [customFeedback, setCustomFeedback] = React.useState();

  // Initialization of boolean state: Whether or not a file is uploaded to the team image editor and can be submitted.
  const [fileUploaded, setfileUploaded] = React.useState(false);

  // Initialization of data state: The image that's being edited by the image editor and will be uploaded upon submission.
  const [tlEditorImage, setEditorImage] = React.useState();

  // Initialization of boolean state: This isn't something that'll be flipped in average use of the app. Nothing in the code currently changes it.
  const [allowZoomOut, setAllowZoomOut] = React.useState(false);

  const currentUser = useSelector((state) => state.session.currentUser)

  // Initialization of float state: The zoom magnification of the editor image. Higher is further out. 1 is the furthest it will go without allowZoomOut being true, and 0 is the closest it will be zoomed in.
  const [zoom, setZoom] = React.useState(1);

  let editor = React.createRef();

  const location = useLocation();

  const dispatch = useDispatch();
    
  async function logout() {
    dispatch(setSession(null));
    
    dispatch(disconnect());
    const logoutQuery = `${env.REACT_APP_BASEURLSERVICE}/auth.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}`

    const postLogoutObj = {
      type:5,
      
      sid: currentUser
    };

    await axios.post(logoutQuery, qs.stringify(postLogoutObj))
  }
  async function onInputChange() {

    // First, it disables submissions. It'll reactivate it later if it meets certain criteria.
    allowTSub(false);

    const u = document.getElementById("teamname").value;

    // It then grabs the current value that's currently in the custom username input box, and sends it to a type 99 SQL request.
    const e = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=99&tn=${u}&c=${env.REACT_APP_COLLECTIONADD}`).then(res => {
        console.log(res);

        // First, it wipes the custom input box of any additional classnames.
        document.getElementById("teamname").className = document.getElementById("teamname").className.replace(" err", "");
        document.getElementById("teamname").className = document.getElementById("teamname").className.replace(" val", "");

        // If rows is 0, that means the current input is valid. Sets the custom feedback to blank and then allows username submissions if the custom username radio button is selected.
        if(res.results.rows == 0 && u.length > 0) {

            // Adds an additional classname that changes the coloration of the border of the textbox to a green color.
            document.getElementById("teamname").className = document.getElementById("teamname").className + " val";

            setCustomFeedback("");

            allowTSub(true)
        }
        else { // Otherwise, set the custom feedback to an error message. Since username submissions are disabled at the beginning of the function, there's no need to disable them now.
            
            // Adds an additional classname that changes the coloration of the border of the textbox to a red color.
            document.getElementById("teamname").className = document.getElementById("teamname").className + " err";
            
            setCustomFeedback("Username can't be blank or a username that's already been registered")
        }
    })
  }

  // Runs on pageload if the user is authenticated. Does a lot of things: Fills in team name, team logo, alongside the team table itself.
  React.useEffect(async () => {
    if(currentUser) {
      //console.log('location', location.pathname); // { key: "3uu089" }
      // Fire whatever function you need.

      // Grab the session token, then-
      const id = currentUser

      // If it's a valid session, and it can find the teamname element
      // (this is necessary because if you're just flipping through pages quickly it will rapidly build up errors and freeze the app as it is not finding the element in question)
      if(id != undefined && document.getElementById("teamname")) {
        console.log(id);
    
        // Grab the team data of the user in question
        const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${id}&c=${env.REACT_APP_COLLECTIONADD}`).then(async (result) => {
          console.log(result);

          let auth = authenticated(result);

          if(!auth) {
            logout();

            toast.error("Unauthenticated session. Please log in again.");
          }
          else {
            if(result.data[0].leagueID == 0) {
              setRedirect(true);
            } else {
              // Initialize the team name of the user, then set the team name state to the results.
              const tn = result.data[0].teamName;
            
              setTeamName(tn);
  
              // Fill the teamname input box with the team name
              if(document.getElementById("teamname")) {
                document.getElementById("teamname").value = tn;
              }
  
              // Set the team logo source to the link within the data that's retrieved.
              if(document.getElementById("teamLogo")) {
                const userPhoto = result.data[0].teamLogo
            
                document.getElementById("teamLogo").src = userPhoto;
              }
  
              // Then, if the user has any players, grab them and put them into the table.
              if(document.getElementById("teamtable") && result.data[0].players.length > 0) {
                const table = document.getElementById("teamtable");
                let row = 0;
  
                let active = 0;

                let total = result.data[0].players.length;

                setTotalCount(total);
                // For each player, create a table row and fill them with.
                for(let i = 0; i < result.data[0].players.length; i++) {
                  // Initialize the current player for brevity's sake.
                  let player = result.data[0].players[i];
                  row += 1;
  
                  // Initialize the new row, then-
                  let tr = document.createElement("tr");
  
                  // -make sure it has an ID we can refer to it by, then,-
                  tr.id = row.toString();
  
                  // -add it to the new table.
                  table.appendChild(tr);
  
                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                  let playerCard = document.createElement("td");
  
                  playerCard.textContent = player.playerName;
  
                  // Placeholder stats for now.
                  // TO DO: Actually insert player stats
                  let statOne = document.createElement("td");
  
                  statOne.textContent = "1";
  
                  let statTwo = document.createElement("td");
  
                  statTwo.textContent = "2";
  
                  let statThree = document.createElement("td");
  
                  statThree.textContent = "3";
  
                  // Initialize the switch for whether or not the player is active.
                  // TO DO: Currently there's no way of knowing whether or not the player in question is currently active.
                  // However, once there is, we should implement actually setting active players in the database to active on the page by default.
                  let toggle = document.createElement("input");
  
                  toggle.id = `toggle${i}`;
  
                  toggle.type = "checkbox";
  
                  toggle.className = "custom-control-input toggleactive";
  
                  toggle.value = player.editionID;
  
                  // This is the code that changes the coloration of the row whenever the player within that row is active or inactive. Eventually it will also be the code that sends out the changes to the database upon activation.
                  // For some reason you can't just call the togglePlayer function here, or else it wil only trgger upon pageload and not when the switch is flipped. To fix this, .bind is called instead.
                  toggle.onchange = togglePlayer.bind(null, player.editionID, toggle.id);
  
                  // Initialize the label for the checkbox.
                  // WARNING: This HAS to be here, even if the text is blank. The custom-switch class checkbox does not appear AT ALL without a label.
                  let togglelabel = document.createElement("label");
  
                  togglelabel.textContent = "Inactive";
  
                  if(player.active == 1) {
                    togglelabel.textContent = "Active";
  
                    tr.className = "activeCard";
  
                    toggle.checked = true;
  
                    active += 1;
  
                    
                  }
  
                  togglelabel.className = "custom-control-label";
  
                  togglelabel.htmlFor = `toggle${i}`;
  
                  // Initialize the table cell for the player activation switch.
                  let toggleContainer = document.createElement("td");
  
                  // Initialize the div that contains the player activation switch. This is also necessary for the custom-switch class to function properly.
                  let togglediv = document.createElement("div");
  
                  togglediv.className = "custom-switch custom-control togglediv"
  
                  togglediv.tabIndex = 0;
  
                  togglediv.setAttribute("data-toggle","tooltip");
  
                  // Add the switch and the switch label to the div, then add the div to the table cell.
                  togglediv.appendChild(toggle);
  
                  togglediv.appendChild(togglelabel);
  
                  toggleContainer.appendChild(togglediv);
  
                  // Then, now that everything's been initialized, add all the cells to the table row.
                  tr.appendChild(playerCard);
  
                  tr.appendChild(statOne);
  
                  tr.appendChild(statTwo);
  
                  tr.appendChild(statThree);
  
                  tr.appendChild(toggleContainer);
                }
  
                setCount(active);
  
                if(active >= 9) {
                  console.log("Gamer");
      
                  const rows = document.getElementsByClassName("toggleactive");
      
                  for(let i = 0; i < rows.length; i++) {
                    let toggle = rows[i];
  
                    let togglediv = toggle.parentElement;
      
                    if(toggle.checked != true) {
                      toggle.disabled = true;
  
                      togglediv.setAttribute("title", "You can only have 9 players active! Deactivate one to be able to select another one.")
                    }
                  }
                }
                $(function () {
                  $('[data-toggle="tooltip"]').tooltip()
                })
              }
            }
          }

          
        })
      }
    }
    
  }, [currentUser]);

  React.useEffect(async () => {
    let toggles = document.getElementsByClassName("toggleactive");

    let num = count;

    for(let i = 0; i < toggles.length; i++) {
      let toggle = toggles[i];

      let togglediv = toggle.parentElement;

      toggle = document.getElementById(toggle.id);

      toggle.onchange = togglePlayer.bind(null, toggle.value, toggle.id, count);

      if(toggle.checked == false && count < 9) {
        toggle.disabled = false;

        togglediv.setAttribute("data-original-title","");
      }

      if(toggle.checked == false && count >= 9) {
        toggle.disabled = true;

        togglediv.setAttribute("data-original-title","You can only have 9 players active! Deactivate one to be able to select another one.");
      }
    }

  }, [count]);


  // The function that runs upon clicking the activation switch for a player.
  async function togglePlayer(edition, id, numActive) {
    console.log(edition);

    const uid = currentUser

    // Grab the switch by it's ID
    let checkbox = document.getElementById(id);

    // Then we can grab the label by grabbing the checkbox's sibling element.
    let label = checkbox.nextSibling

    // Then we grab the tablerow by grabbing a bunch of nested parent elements. input -> div -> td -> tr. Each arrow is a parentElement call.
    let tablerow = checkbox.parentElement.parentElement.parentElement;

    let postObj = {
      type: 300,

      a: 1,

      u: uid,

      c: `${env.REACT_APP_COLLECTIONADD}`,

      e: edition,

      apiKey: env.REACT_APP_SERVICEAPIKEY
    }

    // Now we check if the change results in a checked box or an unchecked box. If it's the former, we change the label and the tablerow's class to reflect that.
    if(checkbox.checked) {
      axios.post(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php`,qs.stringify(postObj)).then(async (postRes) => {
        let res = postRes.data;

        console.log(res);

        if(res.results.rows == 1) {
          tablerow.className = "activeCard";

          label.textContent = "Active"

          setCount(count + 1);
        }
        else {
          checkbox.checked = false;
        }
      })

    }
    else {
      postObj.type = 301;

      postObj.a = 0;

      console.log(postObj);

      axios.post(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php`,qs.stringify(postObj)).then(async (postRes) => {
        let res = postRes.data;

        console.log(res);

        tablerow.className = "";

        label.textContent = "Inactive"

        console.log("Deactivate");

        if(res.results.rows == 1) {
          setCount(count - 1);
        }
      });
    }
  }

  // Submits the tlEditorImage to the SQL database to change the image.
  async function sub(){

    // First case, we're submitting an uploaded-to-the-editor image. Non-.eth, in other words.
    if(true){ // original: document.querySelector('input[name="pfpradio"]:checked') == undefined. Put this back if we decide to let them use doteth names for their team logo.
        const formdata = new FormData();

        const photo = await editor.current.getImageScaledToCanvas().toDataURL();

        const id = currentUser

        let imageURL;

        await fetch(photo).then(async (res) => (imageURL = await res.blob()))

        //const name = "pfp.jpg"

        const image = imageURL;

        formdata.append("image",image);

        const uploadQuery = `${env.REACT_APP_IMAGEUPLOAD}/img`;

        const uploadObj = {
            session: id,

            which: "team"
        }

        formdata.append("info", qs.stringify(uploadObj));

        const a = await axios.post(uploadQuery,formdata, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(async (uploadRes) => {
            console.log(uploadRes.data);
        })

        // TO DO: Replace this stuff with something that sends off the blob to whatever ends up uploading that and setting the pfp
        /*await moralisFile.save().then(async (res) => {
            user.set("pfp", moralisFile);

            await user.save().then(async (response) => {
                const imgUrl = await user.get("pfp").url();

                document.getElementById("pfp").src = imgUrl;

                const c = await fetch(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=10&u=${id}&up=${imgUrl}&c=${env.REACT_APP_COLLECTIONADD}`).then(res => res.json()).then((updateRes) => {
                    console.log(updateRes);

                    setfileUploaded(false);

                    setEditorImage(null);
                })
            });
        })*/
    }
    /*else { // Now we're submitting a .eth profile picture.

        // Just need to grab the session token here. The dotethpfp state is the image link we're using here, so we don't need anything else.
        console.log("here");
        const id = currentUser

        // Send the fetch, then set the pfp image link to the dotethpfp state.
        const c = await fetch(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=10&u=${id}&tl=${dotEthpfp}&c=${env.REACT_APP_COLLECTIONADD}`).then(res => res.json()).then(async (res) => {
            document.getElementById("pfp").src = dotEthpfp;

            let imageURL;

            await fetch(dotEthpfp)
            .then(async (res) => (imageURL = await res.blob()))

            // TO DO: Replace this stuff with something that sends off the blob of the doteth image to whatever ends up uploading that and setting pfp
            /*
            const moralisFile = new Moralis.File("pfp.jpg",imageURL);

            await moralisFile.save().then(async (res) => {
                user.set("pfp", moralisFile);

                await user.save().then(async (response) => {
                    const imgUrl = await user.get("pfp").url();
                });
            })

            user.set("pfp",);
        
            console.log(res);

            document.getElementById("usingDotethpfp").checked = false;/ CLOSE COMMENT HERE
        })
    }*/
}

  // This function handles whatever's uploaded to the team logo image dropzone.
  async function startCropping(file) {
    console.log("Cropping");
    
    // If there's an actual file that's been uploaded, start editing it.
    if(file.length >= 1) {
      if(!fileUploaded) {
        setfileUploaded(true);
      }
      const image = file[0];
      setEditorImage(image);
    }
  }

  // This function is what's called whenever the zoombar in the image editor is used. It grabs the value of the bar, then translates it to something that the zoom variable accepts into the editor..
  function handleScale() {
    const scale = document.getElementById("scale").value;
    setZoom(parseFloat(scale));
  }

  // The submission function for the teamName variable.
  async function setName() {

    // First, grab the new value for the input box and then the session ID,
    const newName = document.getElementById("teamname").value;

    const id = currentUser

    // If it's not just the old name, submit it to the SQL service. It returns either the new name, or if it's an invalid name, just the address of the wallet.
    if(newName != teamName) {
      const b = fetch(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?type=10&apiKey=${env.REACT_APP_SERVICEAPIKEY}&u=${id}&tn=${newName}&c=${env.REACT_APP_COLLECTIONADD}`).then(res => res.json()).then((result) => {
        console.log(result);

        setTeamName(result.data[0].teamName);
      })
    }
  }

  if (!currentUser) { // REPLACE THIS WITH VALID UNAUTHENTICATED LOGIC
    return (
        <div className="background-image-div">
          <div className="bg-div">
            <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
              <source src="/images/bg_final.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
    );
  }

  return (
    <div className="background-image-div">
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">

        <div className="div-content mx-auto bg-home p-lg-5 p-3">
          {redirect && (
            <Navigate to="/league" replace={true} />
          )}
          <div id="tn" className="div-content mx-auto w-75 mt-3">
            <h2>Team Name: {teamName}</h2>
            <label htmlFor="usingCustom" className="w-50 mx-auto justify-content-center"><input type={"text"} className="form-control w-100" id="teamname" onChange={async () => { await onInputChange(); }}  defaultValue={teamName}autoComplete={"off"}></input> <p className="errfeedback">{customFeedback}</p> </label>
            <button className="div-button btn btn-primary mt-3" style={{pointerEvents:"auto"}} disabled={(!allowTeamSub)} onClick={() => setName()}>Set Name</button>
          </div>
          <hr className="hr" id="tl"></hr>
          <div id="" className="div-content mx-auto w-75">
            <img id="teamLogo" className="teamlogo" src="https://storage.googleapis.com/ethballimages/default-team.png" height={250} width={250}></img>
            <form id="tl-form" className="div-form mt-3">
              <h2>Team Logo: </h2>
              <Dropzone accept="image/png, image/jpg, image/jpeg" maxFiles={1} maxSize={2097152} onDrop={acceptedFiles => {console.log(acceptedFiles); startCropping(acceptedFiles)}}>
                {({getRootProps, getInputProps}) => (
                  <section className="dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className="p-3">Either click this background area or drag an image onto it.</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <button className="form-button btn btn-primary" type="button" id="pfp-sub" disabled={!fileUploaded} onClick={async () => { await sub() }}>Submit</button>

              {fileUploaded && (
                <div id="editor" className="editor">
                  <AvatarEditor ref={editor}
                    image={tlEditorImage}
                    scale={zoom}
                    width={250}
                    height={250}
                    border={10}
                    borderRadius={
                      250 / (100 / 50)
                    }
                    color={[1, 1, 1, 0.6]} // RGBA
                    rotate={0}
                  />
                  <br/>
                  Zoom:
                  <input
                    id="scale"
                    name="scale"
                    type="range"
                    onChange={() => {handleScale()}}
                    min={allowZoomOut ? '0.1' : '1'}
                    max="2"
                    step="0.01"
                    defaultValue="1"
                  />
                </div>
              )}
            </form>
          </div>
        </div>
        
        

        <div className="pagebottom"></div>
      </div>
    </div>
  );
};

export default Team;