import { createSlice } from '@reduxjs/toolkit'

export const addressSlice = createSlice({
  name: 'address',
  initialState: {
    currentAddress: null,
  },
  reducers: {
    setAddress: (state, action) => {
      state.currentAddress = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAddress } = addressSlice.actions

export default addressSlice.reducer