import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";

const Footer = () => {
  //const { authenticate, isAuthenticated, user, logout } = useMoralis();

  return (
    <footer className="footer bg-dark py-3">
      <div className="container-fluid footer-container">
        <div className="row mx-0">
          <div className="col">
            <p>© ethball.io, 2023</p>
            <a href="/terms">Terms and Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;