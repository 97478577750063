import "bootstrap/dist/css/bootstrap.min.css"
import $ from "jquery";
import Popper from "popper.js";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
//import { MoralisProvider } from "react-moralis";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Mint,
  About,
  Team,
  Settings,
  League,
  Standings,
  Terms,
  Roster,
  Onboard,
  Beta,
  Button
} from "./components";
import "./index.css"

const APP_ID = "lpMMzTY631poQJvfqQGqFFwiB9ZosJHXp7S4HWBK";
const SERVER_URL = "https://hegutdnsdtjp.usemoralis.com:2053/server";

//const root = ReactDOM.createRoot(document.getElementById("root"))

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/mint" element={<Mint />}/>
            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/league" element={<League />} />
            <Route path="/standings" element={<Standings />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/roster" element={<Roster />} />
            <Route path="/start" element={<Onboard />} />
            <Route path="/beta" element={<Beta />} />
          </Routes>
          < Button />
          <Footer />
        </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);