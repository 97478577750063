import React from "react";
import ReactDOM from "react-dom";
import { HashLink as Link } from "react-router-hash-link";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
//import { useMoralis } from "react-moralis";

const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
    <>
      <button onClick={() => zoomIn()}>+</button>
      <button onClick={() => zoomOut()}>-</button>
      <button onClick={() => resetTransform()}>x</button>
    </>
  );
const Beta = () => {
  //const { authenticate, isAuthenticated, user, logout } = useMoralis();
  /*
  if (!isAuthenticated) {
    return (
      <div id="pagetop" className="background-image-div">
        <div className="bg-div">
            <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                <source src="/images/bg.mp4" type="video/mp4" />
            </video>
        </div>
        
        <div className="div-background-content">
            <div className="align-self-center mx-auto div-content">
                <p></p>
                
            </div>
        </div>
      </div>
    );
  }*/

  
  return (
    <div id="pagetop" className="background-image-div">
        <div className="bg-div">
          <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
            <source src="/images/bg_final.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="div-background-content">

            <div className="mx-auto div-content bg-home px-5 py-3">
                <h1>Beta Test Startup Info</h1>
                <p>
                    Thanks to everyone who is helping us to test the game this season! Your help and feedback will help us guide the decisions we need to make to get the game complete and fun for everyone!
                </p>
                <p><b className="highlightText" >Please note:</b> The following guides contain images that are zoomed in on the relevant location for that instruction. If you'd like to see the entire image for that instruction, click the 'Zoom Out' button. If you would like to return to the zoomed in position of the image, click 'Reset'.</p>
                <p>To get started, everyone involved will need to do the following:</p>
                <ul>
                    <li>Install a Web3 Wallet Browser Extension. We recommend <a href="https://metamask.io/download/" target={"_blank"}>Metamask!</a>
                        <ul>
                            <li><a className="" data-toggle="collapse" href="#collapseBrowsers" role="button" aria-expanded="false" aria-controls="collapseBrowsers">Supported Browsers</a></li>
                            <div className="collapse" id="collapseBrowsers">
                                <div className="card card-body bg-dark">
                                    <li>Supported Browsers:
                                        <ul>
                                            <li>Chrome</li>
                                            <li>Opera</li>
                                            <li>Brave</li>
                                            <li>Firefox</li>
                                            <li>Edge</li>
                                        </ul>
                                    </li>
                                </div>
                            </div>
                            <li><a className="" data-toggle="collapse" href="#collapseMetamaskD" role="button" aria-expanded="false" aria-controls="collapseMetamaskD">Guide to install Web3 Wallet Extension on Mac or PC</a></li>
                        
                            <div className="collapse" id="collapseMetamaskD">
                                <div className="card card-body bg-dark">
                                    <p>1. Click the link to go to the Metamask Download page</p>
                                    <TransformWrapper
                                        initialScale={2.5}
                                        initialPositionX={-475}
                                        initialPositionY={-100}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d1.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>2. Select which browser you want to install the browser plugin. This will be the name of the browser you are using.</p>
                                    <TransformWrapper
                                        initialScale={2.5}
                                        initialPositionX={-350}
                                        initialPositionY={-100}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d2.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>3. Click the ‘Install Metamask’ button</p>
                                    <TransformWrapper
                                        initialScale={2.5}
                                        initialPositionX={-350}
                                        initialPositionY={-500}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d3.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>4. Click 'Add to Chrome'</p>
                                    <TransformWrapper
                                        initialScale={1.75}
                                        initialPositionX={-320}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d4.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>5. On the popup, click ‘Add Extension’</p>
                                    <TransformWrapper
                                        initialScale={2.25}
                                        initialPositionX={-323}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d5.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>6. Select 'Create a New Wallet'</p>
                                    <TransformWrapper
                                        initialScale={1.4}
                                        initialPositionX={-95}
                                        initialPositionY={-85}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d6.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>7. If you would like to share anonymous data with Metamask, click the ‘I Agree’ button, other click ‘No Thanks’. Your choice here does not affect your ability to use the Metamask Web3 Wallet.</p>
                                    <TransformWrapper
                                        initialScale={1.75}
                                        initialPositionX={-175}
                                        initialPositionY={-400}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d7.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>8. Create a password for your wallet. Choose a strong password that you can remember. The plugin will indicate the strength of the password you have chosen.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d8.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>9. The password you choose is stored on your computer, not by Metamask and not by Ethball. IF YOU FORGET THE PASSWORD IT CANNOT BE RECOVERED BY METAMASK OR ETHBALL!!! By checking the box you are agreeing that you understand the password cannot be recovered by anyone. Finally, click ‘Create a new wallet’.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-120}
                                        initialPositionY={-170}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d9.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>10. Watch the ‘Secure your wallet’ video. Really, watch the video!</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d10.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>11. Click ‘Secure my wallet (recommended)’ to start the process of securing your Web3 Wallet.</p>
                                    <TransformWrapper
                                        initialScale={1.75}
                                        initialPositionX={-175}
                                        initialPositionY={-75}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d11.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>12. After watching the video you now realize how important your Secret Recovery Phrase is. Write it down and store it in a safe and secure place. Please note: the exact spelling of the words AND the order of the words matter, so make sure you meticulously write down the exact words and order! If you ever forget your password to your wallet, this recovery phrase will be necessary to get access to your account again. After writing it down, click the ‘Next’ button.</p>
                                    <TransformWrapper
                                        initialScale={1.75}
                                        initialPositionX={-175}
                                        initialPositionY={-300}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d12.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>13. The Metamask extension will now quiz you to make sure you have things written down accurately. Type in the missing words in the appropriate fields and click the ‘Confirm’ button.</p>
                                    <TransformWrapper
                                        initialScale={1.75}
                                        initialPositionX={-175}
                                        initialPositionY={-200}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d13.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>14. If you have entered the information correctly you should see a screen that says ‘Wallet creation successful’. Read the message on the screen and click ‘Got it!’.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d14.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>15. For quick access to the Metamask Web3 browser extension, pin it to the browser toolbar. Start by clicking the browser extension icon in the top right corner of the browser.</p>
                                    <TransformWrapper
                                        initialScale={1.75}
                                        initialPositionX={-175}
                                        initialPositionY={-175}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d15.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>16. In the dropdown, click the pin icon to the right of the Metamask extension.</p>
                                    <TransformWrapper
                                        initialScale={1.75}
                                        initialPositionX={-175}
                                        initialPositionY={-175}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d16.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>17. Click 'Next'</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d17.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>18. With the Metamask browser extension pinned, click the ‘Done’ button.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d18.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>19. The final step is to switch your wallet from the Ethereum Mainnet to the Goerli test network we are using for the Ethball Beta Test. To switch over to the Goerli test network, click the drop down menu in the upper right hand corner.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d19.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>20. In the drop down menu, click the link that says, ‘Show/hide test networks’</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d20.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>21. This should open the Settings page to the ‘Show test networks’ switch. If you do not see it immediately, you may need to scroll up or down in the menu.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d21.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>22. Click on the switch to flip it to the ‘On’ position.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d22.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>23. Scroll to the top of the menu and click the ‘X’ in the upper right hand corner of the menu.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d23.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>24. Select the drop down menu in the upper right hand corner of the page that says ‘Ethereum Mainnet’.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d24.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>25. In the menu you should now see the Ethereum Mainnet option plus at least 2 testnet options, one of which should be the Goerli Testnet. Click on ‘Goerli test network’.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d25.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>26. The drop down menu should now display the Goerli test network by default. Congratulations, you are now ready to login to the Ethball website!</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/d26.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                </div>
                            </div>
                            <li><a className="" data-toggle="collapse" href="#collapseMetamaskI" role="button" aria-expanded="false" aria-controls="collapseMetamaskI">Guide to install Web3 browser app for iPhone</a></li>
                            <div className="collapse" id="collapseMetamaskI">
                                <div className="card card-body bg-dark">
                                    <p>Coming Soon</p>
                                </div>
                            </div>
                            <li><a className="" data-toggle="collapse" href="#collapseMetamaskA" role="button" aria-expanded="false" aria-controls="collapseMetamaskA">Guide to install Web3 browser app for Android</a></li>
                            <div className="collapse" id="collapseMetamaskA">
                                <div className="card card-body bg-dark">
                                    <p>Coming Soon</p>
                                </div>
                            </div>

                        </ul>
                    </li>
                    
                    
                    
                    <li>Join the <a href="https://discord.gg/rusc2JzmEh" target={"_blank"}>Discord Server</a>. If you don't have a Discord Account, follow this <a className="" href="https://support.discord.com/hc/en-us/articles/360033931551-Getting-Started" role="button" target={"_blank"}>guide</a> to create a free Discord Account!
                        <ul>
                            <li><a className="" data-toggle="collapse" href="#collapseDiscord" role="button" aria-expanded="false" aria-controls="collapseDiscord">Guide to join Discord Server</a> (note: membership in the Ethball Discord Server requires you to register your phone number with Discord. Discord does not disclose your phone number to the Ethball Team or anyone else. This is a security measure to help filter out bots.)</li>
                            <div className="collapse" id="collapseDiscord">
                                <div className="card card-body bg-dark">
                                    <p>1. Click the invite link to join the Ethball Discord Server</p>
                                    <TransformWrapper
                                        initialScale={2.5}
                                        initialPositionX={-100}
                                        initialPositionY={-100}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds1.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>2. If you are not currently logged in to Discord, you will see a screen that looks like this. Click on ‘Already have an account?’. If you are already logged in, you can <a href="#ds7">skip to step 7</a>. If you have not created a Discord account yet, click the link <a className="" href="https://support.discord.com/hc/en-us/articles/360033931551-Getting-Started" role="button" target={"_blank"}>here</a> for a guide to create a free Discord Account.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-130}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds2.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>3. Type in the email address and password you used to create your Discord Account.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-125}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds3.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>4. Click ‘Log In’.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-125}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds4.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>5. If you have Two-factor authentication enabled, open your 2fa app and enter the code you see in that app. If you do not have 2fa enabled, <a href="#ds7">skip to step 7</a>. As a side note, we HIGHLY recommend you use 2fa as an additional layer of security. <a href="https://support.discord.com/hc/en-us/articles/219576828-Setting-up-Two-Factor-Authentication" target={"_blank"}>Here is a guide</a> Discord created on how to set that up if you are interested.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-130}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds5.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>6. After typing in your 2fa code, click ‘Log In’.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-130}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds6.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" id="ds7" />
                                    <p>7. Click ‘Accept Invite’ to join the Ethball Discord Server.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-130}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds7.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>8. Please read through rules of the Ethball Discord Server and if you agree to the rules, click the ‘Verify’ button.</p>
                                    <TransformWrapper
                                        initialScale={2}
                                        initialPositionX={-275}
                                        initialPositionY={-550}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds8.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>9. If you are successful you should see all of the channels in the Ethball Discord Server appear in the left hand ‘Channels’ column.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds9.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    
                                </div>
                            </div>
                        </ul>
                    </li>
                    
                    
                    <li>Link Discord to the Ethball account
                        <ul>
                            <li><a className="" data-toggle="collapse" href="#collapseLink" role="button" aria-expanded="false" aria-controls="collapseLink">Guide to link your Discord account to your Ethball account.</a></li>
                            <div className="collapse" id="collapseLink">
                                <div className="card card-body bg-dark">
                                    <p>1. On the ethball.io website, click the wallet icon in the upper right hand corner of the website.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-175}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld1.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>2. On the pop-up window, click on ‘Metamask’.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld2.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>3. The Metamask Web3 Wallet extension should open. Your default account should already be selected. Click ‘Next’ to connect your default account to Ethball.io. (If the Metamask extension menu does not open automatically, look in the upper right hand corner of your browser for the Metamask icon with the notification mark <img src="https://ebimg.ethball.io/demoimg/metamasknotification.png" /> and click that.) If you have logged in to the Ethball.io website with your Metamask wallet previously, you will not see this screen so you can <a href="#ld5">jump to step 5</a>.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld3.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>4. Read the Connection privileges you are agreeing to and hit ‘Connect’.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={-0}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld4.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" id="ld5" />
                                    <p>5. By signing this message, you are agreeing to the Ethball Terms of Service, agreeing that you know and understand how the game works, and that you want to log in to the site. Click ‘Sign’ to accept the terms and log in.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld5.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>6. You will know that you have successfully logged in as the wallet icon will be replaced with your profile icon (or the default profile icon).</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-175}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld6.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>7. Click the profile icon and select ‘Settings’.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-175}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld7.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>8. Click ‘Link to Discord’.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-125}
                                        initialPositionY={-50}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld8.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>9. Linking your Discord account will log you out of the Ethball site to complete the connection. Click ‘Yes’ if you want to link your Discord account to your Ethball account right now.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-125}
                                        initialPositionY={-50}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld9.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>10. If you are not currently logged in to Discord, you will see a screen that looks like this. Click on ‘Already have an account?’. If you are already logged in, you can <a href="#ld15">skip to step 15</a>. If you have not created a Discord account yet, click the link <a className="" href="https://support.discord.com/hc/en-us/articles/360033931551-Getting-Started" role="button" target={"_blank"}>here</a> for a guide to create a free Discord Account.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-130}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds2.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>11. Type in the email address and password you used to create your Discord Account.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-125}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds3.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>12. Click ‘Log In’.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-125}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds4.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>13. If you have Two-factor authentication enabled, open your 2fa app and enter the code you see in that app. If you do not have 2fa enabled, <a href="#ld15">skip to step 15</a>. As a side note, we HIGHLY recommend you use 2fa as an additional layer of security. <a href="https://support.discord.com/hc/en-us/articles/219576828-Setting-up-Two-Factor-Authentication" target={"_blank"}>Here is a guide</a> Discord created on how to set that up if you are interested.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-130}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds5.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>14. After typing in your 2fa code, click ‘Log In’.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-130}
                                        initialPositionY={-140}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ds6.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" id="ld15" />
                                    <p>15. Click ‘Authorize’ to allow Ethball to link to your Discord account.</p>
                                    <TransformWrapper
                                        initialScale={1.25}
                                        initialPositionX={-50}
                                        initialPositionY={-50}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld10.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>16. After successfully linking your Discord account to your Ethball account, you will be redirected back to the Ethball website. Click the wallet icon to login to the Ethball website.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-175}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld11.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>17. On the pop-up, click ‘Metamask’</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={-0}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld12.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>18. Agree to the terms again and click ‘Sign’ to login.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={-0}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld13.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>19. Click the profile icon.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-175}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld14.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>20. Click ‘Settings’.</p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-175}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld15.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>21. And if the link from your Discord account to your Ethball account is successful, you should receive this message that your Discord account is linked.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={-0}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/ld16.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                </div>
                            </div>
                        </ul>
                
                    </li>
                    
                    <li>Request some GoerliΞth from the development team to purchase some packs
                        <ul>
                            <li><a className="" data-toggle="collapse" href="#collapseGoerli" role="button" aria-expanded="false" aria-controls="collapseGoerli">Guide to request Testnet Ethereum (GoerliΞth)</a></li>
                            <div className="collapse" id="collapseGoerli">
                                <div className="card card-body bg-dark">
                                    <p>1. In the Ethball Discord Server, click on the ‘help-tickets’ channel</p>
                                    <TransformWrapper
                                        initialScale={2.5}
                                        initialPositionX={-50}
                                        initialPositionY={-400}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/te1.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>2. In the ‘help-tickets’ channel, click on ‘Create ticket’</p>
                                    <TransformWrapper
                                        initialScale={2.5}
                                        initialPositionX={-325}
                                        initialPositionY={-800}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/te2.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>3. A new ticket should appear under the ‘HELP TICKETS’ header in the channel column on the left. Click on the ticket.</p>
                                    <TransformWrapper
                                        initialScale={2.25}
                                        initialPositionX={-50}
                                        initialPositionY={-650}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/te3.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>4. Type a short message to request some testnet GoerliEth. Hit Enter to submit.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={-0}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/te4.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>5. Someone from the Ethball team will type a response once some GoerliEth has been sent to you. If we have any questions they will be posted in the Help Ticket as well.</p>
                                    <TransformWrapper
                                        initialScale={2}
                                        initialPositionX={-225}
                                        initialPositionY={-600}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/te5.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>6. Once you receive the message that we have sent the GoerliEth, you can close the Help Ticket by clicking the ‘Close’ button.</p>
                                    <TransformWrapper
                                        initialScale={2.25}
                                        initialPositionX={-350}
                                        initialPositionY={-650}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/te6.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>7. Verify that you want to close the ticket by clicking the red ‘Close’ button.</p>
                                    <TransformWrapper
                                        initialScale={2.25}
                                        initialPositionX={-350}
                                        initialPositionY={-650}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/te7.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>8. To verify that you have received your test GoerliEth, click on the Metamask Web3 Browser extension icon in your browser.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={-0}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/te8.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>9. Once the Metamask extension menu opens you should see that you now have a balance of 0.5 GoerliEth. If you do not see a balance, make sure you have ‘Goerli test network’ selected. If it is not selected, click the drop down menu at the top of the extension and select ‘Goerli test network’. If you do not see the ‘Goerli test network’ option in your list, check our guide called ‘Guide to install Web3 Wallet Extension on Mac or PC' for instructions on how to show test networks.</p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={-0}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/te9.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                </div>
                            </div>
                        </ul>
                    </li>

                    <li>Purchase some Ethball Fantasy Baseball Card Packs
                        <ul>
                            <li><a className="" data-toggle="collapse" href="#collapsePacks" role="button" aria-expanded="false" aria-controls="collapsePacks">Guide to purchasing Ethball Packs</a></li>
                            <div className="collapse" id="collapsePacks">
                                <div className="card card-body bg-dark">
                                    <p>1. </p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-175}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp1.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>2. </p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp2.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>3. </p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp3.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>4. </p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={-0}
                                        initialPositionY={-0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp4.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" id="ld5" />
                                    <p>5. </p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-120}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp5.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>6. </p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp6.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>7. </p>
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp7.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>8. </p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-120}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp8.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>9. </p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-120}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp9.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>10. </p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-120}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp10.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>11. </p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-120}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp11.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>12. </p>
                                    <TransformWrapper
                                        initialScale={1.5}
                                        initialPositionX={-120}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp12.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                    <br/>
                                    <hr className="hr hr-home" />
                                    <p>13. </p>
                                    <TransformWrapper
                                        initialScale={1.3}
                                        initialPositionX={-70}
                                        initialPositionY={0}
                                        >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                            <div className="tools btn-group w-25">
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                            </div>
                                            <TransformComponent>
                                                <img src="https://ebimg.ethball.io/demoimg/bp13.png" style={{maxWidth:"500px"}} alt="test" />
                                            </TransformComponent>
                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>
                                </div>
                            </div>
                        </ul>
                    </li>
                    
                    <li>Read through the <Link smooth to="/about#pagetop">Overview</Link> section to learn how to play the game!</li>
                </ul>
                <p>Once you are in the Discord server, open a ticket to request testnet Ethereum. You can do this by opening the #help-tickets channel in the Ethball Discord Server, and click the 'Create ticket' button. We'll provide enough GoerliEth to let you get started purchasing packs!</p>
                <p>We're working on some videos that should clarify many questions that you may have regarding usage of the Web3 Wallet, ethball.io website, OpenSea, etc. In the meantime, if you have any questions, feel free to reach out via Discord or text, and we'll help you as soon as possible!</p>
                <p>Again, many thanks for participating in helping us polish this product. We hope you have fun, and good luck this season!</p>
                <p>-The Ethball Team</p>
            </div>
        </div>
    </div>
  );
};

export default Beta;