import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";

const Standings = () => {
  //const { authenticate, isAuthenticated, user, logout } = useMoralis();

  return (
    <div id="pagetop" className="background-image-div">
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">
            <div className="align-self-center mx-auto div-content">
                <ul className="nav nav-tabs nav-justified" role="tablist">
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Table
                    </a>
                    <ul className="dropdown-menu bg-dark" role="menu" aria-labelledby="menu1">
                      <a className="dropdown-item" id="ts-tab" role="tab" href="#ts" data-toggle="tab">Team Standings</a>
                      <a className="dropdown-item" id="ls-tab" role="tab" href="#ls" data-toggle="tab">Leaders</a>
                      <a className="dropdown-item" id="s-tab" role="tab" href="#s" data-toggle="tab">Schedule</a>
                    </ul>
                  </li>
                </ul>
                
                <div className="tab-content">
                  <div className="tab-pane fade" id="ts" role="tabpanel" aria-labelledby="ts-tab">
                    <table className="table table-light table-hover table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Team Standings</th>
                        </tr>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Points</th>
                          <th scope="col">Players</th>
                        </tr>
                      </thead>
                      <tbody>

                      </tbody>
                    </table>
                  </div>
                  <div className="tab-pane fade" id="ls" role="tabpanel" aria-labelledby="ls-tab">
                    <table className="table table-light table-hover table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Leader Standings</th>
                        </tr>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Points</th>
                          <th scope="col">Role</th>
                        </tr>
                      </thead>
                      <tbody>

                      </tbody>
                    </table>
                  </div>
                  <div className="tab-pane fade" id="s" role="tabpanel" aria-labelledby="s-tab">
                    <table className="table table-light table-hover table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Schedule</th>
                        </tr>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Points</th>
                          <th scope="col">Players</th>
                        </tr>
                      </thead>
                      <tbody>

                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
        </div>
      </div>
  );
};

export default Standings;