import React, { useRef } from "react";
import ReactDOM from "react-dom";
//import { MoralisProvider, useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { useLocation } from "react-router-dom";
import { BrowserRouter as Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
//import Moralis from "moralis";
import {isMobile, deviceDetect} from "react-device-detect";
import detectEthereumProvider from "@metamask/detect-provider";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const qs = require("qs")
const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;

const Mint = (props) => {
    // Moralis functions and properties.
    //const { authenticate, isAuthenticated, user, logout, enableWeb3 } = useMoralis();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const currentUser = useSelector((state) => state.session.currentUser)
    const [CONFIG, SET_CONFIG] = React.useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
          NAME: "",
          SYMBOL: "",
          ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });

    // Initialization of string state: This is the message that's displayed above the mint button.
    const [feedback, setFeedback] = React.useState(`Click Buy to buy a pack.`);

    // Initialization of string state: This is the state that displays the username in the welcome message.
    const [username, setUsername] = React.useState();

    // Initialization of string state: This is a test state. Remove this for production versions.
    //const [testfeedback, settestfeedback] = React.useState(`Test`)

    const dispatch = useDispatch();
    
    async function logout() {
        dispatch(setSession(null));
        
        dispatch(disconnect());
        const logoutQuery = `${env.REACT_APP_BASEURLSERVICE}/auth.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}`

        const postLogoutObj = {
        type:5,
        
        sid: currentUser
        };

        await axios.post(logoutQuery, qs.stringify(postLogoutObj))
    }

    // Runs on pageload if user is authenticated by Moralis. Currently it just fills in the username state.
    
    React.useEffect(async () => {
        if(currentUser) {

            //console.log('location', location.pathname); // { key: "3uu089" }
            // Fire whatever function you need.

            // Initializes all the variables that go into the query
            const id = currentUser

            const typeoneadd = `${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${id}&c=${env.REACT_APP_COLLECTIONADD}`

            //console.log(typeoneadd)

            // Sends the fetch to SQL server, then sets the username it receives from it. 
            const c = await fetchAsync(typeoneadd).then(async (unameRes) => {
                let auth = authenticated(unameRes);
                if(!auth) {
                    logout();

                    toast.error("Unauthenticated session. Please log in again.");
                }
                else {
                    const uname = unameRes.data[0].userName;
                
                    setUsername(uname);
                }
                
                    
            })
            
        }
    }, [currentUser]);

    const location = useLocation();

    // These two functions raise or lower the amount that you're trying to mint
    // TO DO: Add a limit. Can't go lower than 1, can't go higher than 9/10 at once, irrespective of the contract limit.
    function decrement() {
        const val = document.getElementById("mint-input").value;

        if(val != 1) {
            document.getElementById("mint-input").stepDown();
        }
    }

    function increment() {
        const val = document.getElementById("mint-input").value;

        if(val < 5) {
            document.getElementById("mint-input").stepUp();
        }
    }

    // This is a test function. Remove it later. It grabs the device data of the user and sets the testfeedback state to it.
    /*async function logBrowser() {
        const a = deviceDetect();

        settestfeedback(JSON.stringify(a));
    }*/

    // This function mints an NFT.
    const claimNFTs = (amount) => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * amount);
        let totalGasLimit = String(gasLimit * amount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Purchasing your ${CONFIG.NFT_NAME}, Stand By...`);
        //console.log(CONFIG);
        //setClaimingNft(true);
        blockchain.smartContract.methods
        .mintPack(amount)
        .send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei,
        })
        .once("error", (err) => {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            //setClaimingNft(false);
        })
        .then((receipt) => {
            console.log(receipt);
            setFeedback(
              `Your purchase has been completed! Go to Manage Roster to view your new players! Please be patient, players may not appear immediately on your roster.`
            );
            //setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
        });
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
          dispatch(fetchData(blockchain.account));
        }
    };
    
    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const config = await configResponse.json();

        //console.log(config);
        SET_CONFIG(config);
    };
    
    React.useEffect(() => {
        getConfig();
    }, []);
    
    React.useEffect(() => {
        getData();
    }, [blockchain.account]);

    if (!currentUser) { // Replace with unauthenticated logic
        return (
            <div id="pagetop" className="background-image-div d-inline-flex flex-column">
                <div className="bg-div">
                    <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                        <source src="/images/bg_final.mp4" type="video/mp4" />
                    </video>
                </div>
                
                <div className="div-background-content">
                    <div className="div-content mx-auto p-lg-5 p-3">
                        <div className="bg-primary py-5 w-100 text-center rounded">
                            <h2 className="feedback ml-3 mb-2" style={{color:"yellow"}} id="feedback">Buy a pack!</h2>
                            <br/>
                            <p>Buy a pack or two - Costs 0.025 GoerliΞ for participants in the Alpha/Beta phase, 5 cards per pack.</p>
                            <p>Log into the site with an Ethereum wallet to make a purchase!</p>
                        </div>
                    </div>
                </div>       
                
            </div>
        );
    }

    /*<div className="bg-div">
                <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                    <source src="/images/bg.mp4" type="video/mp4" />
                </video>
            </div>*/

    return (
        <div id="pagetop" className="background-image-div d-inline-flex flex-column">
            <div className="bg-div">
                <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                    <source src="/images/bg_final.mp4" type="video/mp4" />
                </video>
            </div>
                
            <div className="div-background-content">
                <div className="div-content mx-auto p-lg-5 p-3">
                    <div className="bg-primary py-5 w-100 text-center">
                        <h2 className="feedback ml-3 mb-2 header-y" id="feedback">{feedback}</h2>
                        <br/>
                        <p>Buy a pack or two - Costs 0.025 GoerliΞ for participants in the Alpha/Beta phase, 5 cards per pack</p>
                        <p className="ml-3">Packs to be bought: <input className="bg-warning text-dark rounded" style={{width:"40px", fontSize:"20px"}} type={"number"} id="mint-input" value={1} readOnly></input></p>
                        <div className="button-div" style={{width: "100%"}}>
                            <button id="decrement" className="amount-button mr-2 btn btn-warning" onClick={async () => { decrement() }}>-</button>
                            <button className="btn btn-light text-dark" id="mint-button" onClick={async () => {await claimNFTs(document.getElementById("mint-input").value)}}>Buy</button>
                            <button id="increment" className="amount-button ml-2 btn btn-warning" onClick={async () => { increment() }}>+</button>
                        </div>
                    </div>
                </div>
            </div>       
        </div>
    );
    
};

export default Mint;