import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    currentUser: null,
  },
  reducers: {
    setSession: (state, action) => {
      state.currentUser = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSession } = sessionSlice.actions

export default sessionSlice.reducer