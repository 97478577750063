import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";
//import Moralis from "moralis";
import { useLocation, Navigate } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import Dropzone from "react-dropzone";
import $ from "jquery";
import axios from "axios";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
import ModalImage from "react-modal-image"

const qs = require("qs");
const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;

const Roster = () => {

  // Moralis functions and properties.
  //const { authenticate, isAuthenticated, user, logout,  } = useMoralis();

  const [count, setCount] = React.useState(0);

  const [hitterCount, setHitterCount] = React.useState(0);

  const [starterCount, setStarterCount] = React.useState(0);

  const [relieverCount, setRelieverCount] = React.useState(0);

  const [totalCount, setTotalCount] = React.useState(0);

  const [hitterTotalCount, setHitterTotalCount] = React.useState(0);

  const [starterTotalCount, setStarterTotalCount] = React.useState(0);

  const [relieverTotalCount, setRelieverTotalCount] = React.useState(0);

  const [redirect,setRedirect] = React.useState(false);

  const currentUser = useSelector((state) => state.session.currentUser)

  const location = useLocation();

  const dispatch = useDispatch();
    
  async function logout() {
    dispatch(setSession(null));
    
    dispatch(disconnect());
    const logoutQuery = `${env.REACT_APP_BASEURLSERVICE}/auth.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}`

    const postLogoutObj = {
    type:5,
    
    sid: currentUser
    };

    await axios.post(logoutQuery, qs.stringify(postLogoutObj))
  }

  // Runs on pageload if the user is authenticated. Does a lot of things: Fills in team name, team logo, alongside the team table itself.
  React.useEffect(async () => {
    if(currentUser) {
      //console.log('location', location.pathname); // { key: "3uu089" }
      // Fire whatever function you need.

      // Grab the session token, then-
      const id = currentUser

      // If it's a valid session, and it can find the roster element
      // (this is necessary because if you're just flipping through pages quickly it will rapidly build up errors and freeze the app as it is not finding the element in question)
      if(id != undefined && document.getElementById("roster")) {
        console.log(id);
    
        // Grab the team data of the user in question
        const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${id}&c=${env.REACT_APP_COLLECTIONADD}`).then(async (result) => {
          console.log(result);

          let auth = authenticated(result);

          if(!auth) {
            logout();

            toast.error("Unauthenticated session. Please log in again.");
          }
          else {
            if(result.data[0].leagueID == 0) {
              setRedirect(true);
            } else {
              // Then, if the user has any players, grab them and put them into the table.
              if(document.getElementById("teamtable") && result.data[0].players.length > 0) {
                const table = document.getElementById("teamtable");

                const hitterTable = document.getElementById("hittertable");
                let row = 0;
  
                let active = 0;

                let activeHitters = 0;

                let totalHitters = 0;

                let activeStarters = 0;

                let totalStarters = 0;

                let activeRelievers = 0;

                let totalRelievers = 0;

                let total = result.data[0].players.length;

                let todayComposite = result.data[0].compositeNumber;

                setTotalCount(total);
                // For each player, create a table row and fill them with.
                for(let i = 0; i < result.data[0].players.length; i++) {
                  // Initialize the current player for brevity's sake.
                  let player = result.data[0].players[i];
                  row += 1;
  
                  // Initialize the new row, then-
                  let tr = document.createElement("tr");

                  let playerData = JSON.stringify(player);

                  $(tr).attr("data-player",playerData);
  
                  // -make sure it has an ID we can refer to it by, then,-
                  tr.id = row.toString();

                  let t = table;

                  if(player.playerType == "hitter") {
                    t = hitterTable;
                  }
  
                  // -add it to the new table.
                  t.appendChild(tr);

                  let playerImg= document.createElement("td");

                  playerImg.classList.add("imgcont","align-middle")

                  let img = <ModalImage
                        className={`rosterimg`}
                        small={`${player.editionURL}`}
                        medium={`${player.editionURL}`}
                    />
                  ReactDOM.render(img,playerImg)
  
                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                  let playerCard = document.createElement("td");

                  playerCard.setAttribute('style', 'white-space: pre;');

                  playerCard.classList.add("align-middle");

                  let toggletype = player.playerType

                  let playerType = player.playerType;

                  let role = "";

                  if(playerType == "pitcher") {
                    if(player.role == "reliever") {
                      role = "Relief ";
                    }
                    else {
                      role = "Starting "
                    }
                  }
                  if(player.playerType == "hitter") {
                    totalHitters++;
                  }
                  if(player.playerType == "pitcher") {
                    if(player.role == "reliever") {
                      totalRelievers++
                    } else {
                      totalStarters++
                    }
                  }

                  playerType = playerType.charAt(0).toUpperCase() + playerType.slice(1);
  
                  playerCard.textContent = player.playerName + `\r\n${role}${playerType}`;
  
                  // Placeholder stats for now.
                  // TO DO: Actually insert player stats
                  let statOne = document.createElement("td");

                  statOne.classList.add("align-middle");
  
                  statOne.textContent = `${player.composite1.fname} ${player.composite1.lname} (${player.composite1.composite1Value})`;
  
                  let statTwo = document.createElement("td");

                  statTwo.classList.add("align-middle");
  
                  statTwo.textContent = `${player.composite2.fname} ${player.composite2.lname} (${player.composite2.composite2Value})`;
  
                  let statThree = document.createElement("td");

                  statThree.classList.add("align-middle");
  
                  statThree.textContent = `${player.composite3.fname} ${player.composite3.lname} (${player.composite3.composite3Value})`;

                  let today;

                  let tomorrow;

                  switch(todayComposite) {
                    case 2:
                      today = statTwo;
                      tomorrow = statThree;
                      break;
                    case 3:
                      today = statThree;
                      tomorrow = statOne
                      break;
                    default:
                      today = statOne;
                      tomorrow = statTwo;
                  }

                  today.classList.add("today-text");

                  tomorrow.classList.add("tomorrow-text")
  
                  // Initialize the switch for whether or not the player is active.
                  // TO DO: Currently there's no way of knowing whether or not the player in question is currently active.
                  // However, once there is, we should implement actually setting active players in the database to active on the page by default.
                  let toggle = document.createElement("input");
  
                  toggle.id = `toggle${i}`;
  
                  toggle.type = "checkbox";
  
                  toggle.classList.add("custom-control-input", "toggleactive");
  
                  toggle.value = player.editionID;
  
                  // This is the code that changes the coloration of the row whenever the player within that row is active or inactive. Eventually it will also be the code that sends out the changes to the database upon activation.
                  // For some reason you can't just call the togglePlayer function here, or else it wil only trgger upon pageload and not when the switch is flipped. To fix this, .bind is called instead.
                  toggle.onchange = togglePlayer.bind(null, player.editionID, toggle.id);
  
                  // Initialize the label for the checkbox.
                  // WARNING: This HAS to be here, even if the text is blank. The custom-switch class checkbox does not appear AT ALL without a label.
                  let togglelabel = document.createElement("label");
  
                  togglelabel.textContent = "Inactive";
  
                  if(player.active == 1) {
                    togglelabel.textContent = "Active";
  
                    tr.classList.add("activeCard");
  
                    toggle.checked = true;
  
                    active += 1;
  
                    if(player.playerType == "hitter") {
                      activeHitters++;
                    }
                    if(player.playerType == "pitcher") {
                      if(player.role == "reliever") {
                        activeRelievers++
                      } else {
                        activeStarters++
                      }
                    }
                  }
  
                  togglelabel.classList.add("custom-control-label");
  
                  togglelabel.htmlFor = `toggle${i}`;
  
                  // Initialize the table cell for the player activation switch.
                  let toggleContainer = document.createElement("td");

                  toggleContainer.classList.add("align-middle")
  
                  // Initialize the div that contains the player activation switch. This is also necessary for the custom-switch class to function properly.
                  let togglediv = document.createElement("div");
  
                  togglediv.classList.add("custom-switch", "custom-control", "togglediv")
  
                  togglediv.tabIndex = 0;
  
                  togglediv.setAttribute("data-toggle","tooltip");
  
                  // Add the switch and the switch label to the div, then add the div to the table cell.
                  togglediv.appendChild(toggle);
  
                  togglediv.appendChild(togglelabel);
  
                  toggleContainer.appendChild(togglediv);
  
                  // Then, now that everything's been initialized, add all the cells to the table row.
                  tr.appendChild(playerImg);

                  tr.appendChild(playerCard);
  
                  tr.appendChild(statOne);
  
                  tr.appendChild(statTwo);
  
                  tr.appendChild(statThree);
  
                  tr.appendChild(toggleContainer);
                }
  
                setCount(active);

                setHitterCount(activeHitters);

                setHitterTotalCount(totalHitters);

                setStarterCount(activeStarters);

                setStarterTotalCount(totalStarters);

                setRelieverCount(activeRelievers);

                setRelieverTotalCount(totalRelievers);
  
                if(active >= 20) {
                  console.log("Gamer");
      
                  const rows = document.getElementsByClassName("toggleactive");
      
                  for(let i = 0; i < rows.length; i++) {
                    let toggle = rows[i];
  
                    let togglediv = toggle.parentElement;
      
                    if(toggle.checked != true) {
                      toggle.disabled = true;
  
                      togglediv.setAttribute("title", "You can only have 20 players active! Deactivate one to be able to select another one.")
                    }
                  }
                }
                $(function () {
                  $('[data-toggle="tooltip"]').tooltip()
                })
              }
            }
          }

          
        })
      }
    }
    
  }, [currentUser]);

  React.useEffect(async () => {
    let toggles = document.getElementsByClassName("toggleactive");

    let hcount = 0;

    let rcount = 0;

    let scount = 0;

    for(let i = 0; i < toggles.length; i++) {
      let toggle = toggles[i];

      let togglediv = toggle.parentElement;

      let player = JSON.parse(togglediv.parentElement.parentElement.getAttribute("data-player"));

      

      //console.log(data);

      toggle = document.getElementById(toggle.id);

      toggle.onchange = togglePlayer.bind(null, toggle.value, toggle.id, count);

      if(toggle.checked == false && count < 20) {
        toggle.disabled = false;

        togglediv.setAttribute("data-original-title","");
      }

      if(toggle.checked == false && count >= 20) {
        toggle.disabled = true;

        togglediv.setAttribute("data-original-title","You can only have 20 players active! Deactivate one to be able to select another one.");
      }

      if(toggle.checked) {
        if(player.playerType == "hitter") {
          hcount++
        }
        if(player.playerType == "pitcher") {
          if(player.role == "reliever") {
            rcount++
          } else {
            scount++
          }
        }
      }

      
    }

    setHitterCount(hcount);

    setRelieverCount(rcount);

    setStarterCount(scount);

  }, [count]);


  // The function that runs upon clicking the activation switch for a player.
  async function togglePlayer(edition, id) {
    const uid = currentUser

    // Grab the switch by it's ID
    let checkbox = document.getElementById(id);

    // Then we can grab the label by grabbing the checkbox's sibling element.
    let label = checkbox.nextSibling

    // Then we grab the tablerow by grabbing a bunch of nested parent elements. input -> div -> td -> tr. Each arrow is a parentElement call.
    let tablerow = checkbox.parentElement.parentElement.parentElement;



    let postObj = {
      type: 300,

      a: 1,

      u: uid,

      c: `${env.REACT_APP_COLLECTIONADD}`,

      e: edition,

      apiKey: env.REACT_APP_SERVICEAPIKEY
    }

    // Now we check if the change results in a checked box or an unchecked box. If it's the former, we change the label and the tablerow's class to reflect that.
    if(checkbox.checked) {
      axios.post(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php`,qs.stringify(postObj)).then(async (postRes) => {
        let res = postRes.data;

        console.log(res);

        if(res.results.rows == 1) {
          let data = res.data[0];

          let type = data.compositeType;

          let role = data.role;
          tablerow.className = "activeCard";

          label.textContent = "Active"

          setCount(count + 1);
        }
        else {
          checkbox.checked = false;
        }
      })

    }
    else {
      postObj.type = 301;

      postObj.a = 0;

      console.log(postObj);

      axios.post(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php`,qs.stringify(postObj)).then(async (postRes) => {
        let res = postRes.data;

        let data = res.data[0];

        let type = data.compositeType;

        let role = data.role;

        console.log(res);

        tablerow.className = "";

        label.textContent = "Inactive"

        console.log("Deactivate");

        setCount(count-1);
      });
    }
  }

  if (!currentUser) { // REPLACE THIS WITH VALID UNAUTHENTICATED LOGIC
    return (
        <div className="background-image-div">
          <div className="bg-div">
            <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
              <source src="/images/bg_final.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
    );
  }

  return (
    <div className="background-image-div">
        {redirect && (
          <Navigate to="/league" replace={true} />
        )}
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">
        <div className="div-content mx-auto mt-5" id="roster">
            <div className="container players pt-3 pb-3 mb-5 w-sm-50 w-75 sticky-top" style={{top:"7%"}}>
                <div className="row" id="tt-head">
                    <div className="col text-center" id="totalPlayers">Players: {totalCount}</div>

                    <div className="col text-center" id="numActive">Active: {count}</div>
                </div>
                <div className="row mt-2" id="tt-subhead">
                    <div className="col row text-center" id="totalPlayers">
                      <div className="col">Hitters: {hitterTotalCount}</div>
                      <div className="col">Starters: {starterTotalCount}</div>
                      <div className="col">Relievers: {relieverTotalCount}</div>
                    </div>

                    <div className="col row text-center" id="numActive">
                      <div className="col">Hitters: {hitterCount}</div>
                      <div className="col">Starters: {starterCount}</div>
                      <div className="col">Relievers: {relieverCount}</div>
                    </div>
                </div>
            </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
              <table className="table table-light">
                <thead className="text-center">
                    <tr>
                      <th></th>
                      <td style={{width: "25%"}}><b>Today's Composite</b></td>
                      <td style={{width: "25%"}}><i>Tomorrow's Composite</i></td>
                      <td style={{width: "25%"}}>Yesterday's Composite</td>
                      <td style={{width: "25%"}} className="activeCard">Active Player</td>
                      
                    </tr>
                </thead>
              </table>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            <table className="table table-light">
              <thead style={{backgroundColor: "#B9B9B9"}}>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Bat/Batting Avg</th>
                    <th>Muscles/Home Runs</th>
                    <th>Shoes/Steals</th>
                    <th>Active?</th>
                    
                  </tr>
              </thead>
              <tbody id="hittertable">
                
              </tbody>
            </table>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            
            <table className="mt-4 table table-light">
              <thead>
                  <tr style={{backgroundColor: "#B9B9B9"}}>
                    <th></th>
                    <th>Name</th>
                    <th>Hat/Saves</th>
                    <th>Muscles/Strikeouts</th>
                    <th>Shoes/Innings Pitched</th>
                    <th>Active?</th>
                    
                  </tr>
              </thead>
              <tbody id="teamtable">
                
              </tbody>
            </table>

            
          </div>
        </div>
        

        <div className="pagebottom"></div>
      </div>
    </div>
  );
};

// OLD ROSTER
/*
    <div className="background-image-div">
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">
        <div className="div-content mx-auto mt-5" id="roster">
          <div className="overflow-auto h-75 w-50">
            <table className="table table-light">
              <thead>
                  <tr>
                    <td value={totalCount}>
                      Players: {totalCount}
                    </td>
                    <td value={count} id="numActive">
                      Active: {count}
                    </td>
                    
                  </tr>
              </thead>
              <tbody id="teamtable">
                
              </tbody>
            </table>
          </div>
        </div>
        

        <div className="pagebottom"></div>
      </div>
    </div>
*/

// CARD ROSTER
/*
React.useEffect(async () => {
    if(currentUser) {
      //console.log('location', location.pathname); // { key: "3uu089" }
      // Fire whatever function you need.

      // Grab the session token, then-
      const id = currentUser

      // If it's a valid session, and it can find the roster element
      // (this is necessary because if you're just flipping through pages quickly it will rapidly build up errors and freeze the app as it is not finding the element in question)
      if(id != undefined && document.getElementById("roster")) {
        console.log(id);
    
        // Grab the team data of the user in question
        const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${id}&c=${env.REACT_APP_COLLECTIONADD}`).then(async (result) => {
          console.log(result);

          let auth = authenticated(result);

          if(!auth) {
            logout();

            toast.error("Unauthenticated session. Please log in again.");
          }
          else {
            if(result.data[0].leagueID == 0) {
              window.location.replace("./league");
            } else {
              // Then, if the user has any players, grab them and put them into the table.
              if(document.getElementById("teamtable") && result.data[0].players.length > 0) {
                const table = document.getElementById("teamtable");
                let col = 0;

                let row = 0;
  
                let active = 0;

                let total = result.data[0].players.length;

                setTotalCount(total);
                // For each player, create a grid col and fill them in.
                for(let i = 0; i < result.data[0].players.length; i++) {
                  // Initialize the current player for brevity's sake.
                  let player = result.data[0].players[i];

                  if(i % 3 == 0) {
                    row++;

                    let newRow = document.createElement("div");

                    newRow.id = `row-${row}`;

                    newRow.classList.add("row","justify-content-around","mb-5");

                    table.appendChild(newRow);
                  }

                  let currentRow = document.getElementById(`row-${row}`);

                  col++;
  
                  // Initialize the new col, then-
                  let tr = document.createElement("div");
  
                  // -make sure it has an ID we can refer to it by, then,-
                  tr.id = `col-${col}`

                  tr.classList.add("col-3");
  
                  // -add it to the new table.
                  currentRow.appendChild(tr);

                  let playerRow = document.createElement("div");

                  playerRow.id =`col-${col}-row`;

                  playerRow.classList.add("row","playerStats");

                  tr.appendChild(playerRow);
  
                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                  let playerCard = document.createElement("div");

                  playerCard.classList.add("col","text-center","align-self-center");
  
                  playerCard.textContent = player.playerName;
  
                  // Placeholder stats for now.
                  // TO DO: Actually insert player stats
                  let statOne = document.createElement("div");

                  statOne.classList.add("col","text-center","align-self-center");
  
                  statOne.textContent = "1";
  
                  let statTwo = document.createElement("td");

                  statTwo.classList.add("col","text-center","align-self-center");
  
                  statTwo.textContent = "2";
  
                  let statThree = document.createElement("td");

                  statThree.classList.add("col","text-center","align-self-center");
  
                  statThree.textContent = "3";
  
                  // Initialize the switch for whether or not the player is active.
                  // TO DO: Currently there's no way of knowing whether or not the player in question is currently active.
                  // However, once there is, we should implement actually setting active players in the database to active on the page by default.
                  /*let toggle = document.createElement("input");
  
                  toggle.id = `toggle${i}`;
  
                  toggle.type = "checkbox";
  
                  toggle.className = "custom-control-input toggleactive";
  
                  toggle.value = player.editionID;*/
  
                  // This is the code that changes the coloration of the row whenever the player within that row is active or inactive. Eventually it will also be the code that sends out the changes to the database upon activation.
                  // For some reason you can't just call the togglePlayer function here, or else it wil only trgger upon pageload and not when the switch is flipped. To fix this, .bind is called instead.
                  
                  //toggle.onchange = togglePlayer.bind(null, player.editionID, toggle.id);
  
                  // Initialize the label for the checkbox.
                  // WARNING: This HAS to be here, even if the text is blank. The custom-switch class checkbox does not appear AT ALL without a label.
                  
                  /*let togglelabel = document.createElement("label");
  
                  togglelabel.textContent = "Inactive";
  
                  if(player.active == 1) {
                    togglelabel.textContent = "Active";
  
                    tr.className = "activeCard";
  
                    toggle.checked = true;
  
                    active += 1;
  
                    
                  }
  
                  togglelabel.className = "custom-control-label";
  
                  togglelabel.htmlFor = `toggle${i}`; */
  
                  // Initialize the table cell for the player activation switch.
                  
                  //let toggleContainer = document.createElement("td");
  
                  // Initialize the div that contains the player activation switch. This is also necessary for the custom-switch class to function properly.
                  
                  /*let togglediv = document.createElement("div");
  
                  togglediv.className = "custom-switch custom-control togglediv"
  
                  togglediv.tabIndex = 0;
  
                  togglediv.setAttribute("data-toggle","tooltip");*/
  
                  // Add the switch and the switch label to the div, then add the div to the table cell.
                  /*togglediv.appendChild(toggle);
  
                  togglediv.appendChild(togglelabel);
  
                  toggleContainer.appendChild(togglediv);
  
                  // Then, now that everything's been initialized, add all the cells to the table row.
                  playerRow.appendChild(playerCard);
  
                  playerRow.appendChild(statOne);
  
                  playerRow.appendChild(statTwo);
  
                  playerRow.appendChild(statThree);
  
                  //tr.appendChild(toggleContainer);
                }
  
                setCount(active);
  
                if(active >= 9) {
                  console.log("Gamer");
      
                  const rows = document.getElementsByClassName("toggleactive");
      
                  for(let i = 0; i < rows.length; i++) {
                    let toggle = rows[i];
  
                    let togglediv = toggle.parentElement;
      
                    if(toggle.checked != true) {
                      toggle.disabled = true;
  
                      togglediv.setAttribute("title", "You can only have 9 players active! Deactivate one to be able to select another one.")
                    }
                  }
                }
                $(function () {
                  $('[data-toggle="tooltip"]').tooltip()
                })
              }
            }
          }

          
        })
      }
    }
    
  }, [currentUser]);


  <div className="background-image-div">
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">
        <div className="div-content mx-auto mt-5 mb-5" id="roster">
          <div className="overflow-auto h-75 w-75">
            <div className="container" id="teamtable">
                <div className="row mb-5" id="tt-head">
                    <div className="col" id="totalPlayers">Players: {totalCount}</div>

                    <div className="col" id="numActive">Active: {count}</div>
                </div>
            </div>
          </div>
        </div>
        

        <div className="pagebottom"></div>
      </div>
    </div>
*/

export default Roster;